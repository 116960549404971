<template>
  <div class='bottom-content'>
    <div class="bottom-center">
      <div class="top no-select">
        <img src="@/assets/Fill 1.png" style="width:42rem;height:30rem;margin-right:11rem">
        <router-link v-for="item in routeList" :key="item.name" class="center-route" :style="`color:${item.current && routeName !== 'Sign'?'#F93086':'#fff'}`" :to="item.path">
          {{item.display}}
        </router-link>
        <div style="flex:1"></div>
        <div style="display:flex">
          <!-- <img class="third-icon" src="@/assets/底部-微信.png" v-if="!isBindWx">
          <img class="third-icon" src="@/assets/底部-微信备份.png" v-else> -->
          <img class="third-icon" src="@/assets/2-facebook.png"   >
          <img class="third-icon" src="@/assets/2-twitter.png">
          <img class="third-icon" src="@/assets/2-ins.png">
          <img class="third-icon" src="@/assets/2-youtube.png">
          <img class="third-icon" src="@/assets/2-strava.png">
        </div>
      </div>
      <div class="line"></div>
      <div class="bottom">
        <div style="line-height:21rem;">
         <router-link  to="/Privacy" target="_blank"><a class="icp-link">Privacy Policy</a></router-link> <br/>
         <router-link  to="/TermSevice" target="_blank"><a class="icp-link">Terms of Service</a></router-link> 
        </div>
         <!-- <div style="line-height:21rem">powerfun<br>address:XXXXXXXXXX</div> -->
        <div style="line-height:21rem;text-align: right;">Copyright © 2012-2021 PowerFun<br> <a href="https://beian.miit.gov.cn/#/Integrated/index" class="icp-link" target="_blank">沪ICP备16001908号-2</a></div>
      </div>
    </div>
  </div>
</template>

<script>
import helper from "@/utils/helper";
export default {
  name: "BottomNavigator",
  data() {
    return {
      routeList: helper.routeList,
      currentUser: null,
      isBindWx:false
    };
  },
  beforeMount() {
    this.routeName = this.$route.name;
    let centerRoute = this.routeList.find((x) => x.name === this.$route.name);
    if (centerRoute) {
      centerRoute.current = true;
    }
    const userJson = sessionStorage.getItem("CurrentUser")
    if(userJson){
      this.currentUser = JSON.parse(userJson);
    }else{
      this.currentUser = null;
    }
    if(this.currentUser){
      if(this.currentUser.Unionid.replace(/(^s*)|(s*$)/g, '').length ==0){
        this.isBindWx = false
      }else{
        this.isBindWx = true
      }
    }else{
      this.isBindWx = false
    }
    // console.log(39,'bind',this.isBindWx,this.currentUser)
  },
  methods: {
    GoPage(name) {
      if (!this.$router.options.routes.find((x) => x.name === name)) {
        this.$toasted.show('暂未开放',{
          type:"error"
        })
        return;
      }
      if (name === this.routeName) {
        return;
      }
      this.$router.replace({ name: name });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bottom-content {
  /* width: 1920rem; */
  width: 100%;
  height: 149rem;
  background: #252525;
  display: flex;
  justify-content: center;
}
.link{
  color:#737373
}
.link:hover{
  color:#fff
}
.icp-link{
  color:#737373;
}
.icp-link:hover{
  color:#fff;
}

.link:hover{
  color:#fff
}
.bottom-center {
  width: 1200rem;
  height: 100%;
}
.bottom-center .line {
  width: 1200rem;
  height: 1px;
  background: #444444;
}
.bottom-center .top {
  height: 70rem;
  display: flex;
  align-items: center;
}
.bottom-center .top .center-route {
  font-size: 12rem;
  font-family: "GenSenMaruGothicTW-Bold";
  color: #fff;
  margin-right: 32rem;
  cursor: pointer;
}
.bottom-center .top .third-icon{
  margin-left: 10rem;
  width: 30rem;
  height: 30rem;
}
.bottom-center .bottom {
  height: 70rem;
  display: flex;
  padding-top: 10rem;
  /* padding-bottom: 26rem; */
  font-size: 12rem;
  color: #737373;
  font-family: "GenSenMaruGothicTW-Medium";
  font-weight: 500;
  justify-content: space-between;
}
</style>
