<template>
  <div id="app" v-cloak ref="app" @click="ClickOther">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
  </div>
</template>

<script>
import helper from "./utils/helper";
import Vue from "vue";
import TopNavigator from "@/components/TopNavigator";
export default {
  name: "App",
  beforeCreate() {
    window.onresize = () => {
      let xs = 1920,
        width = document.documentElement.clientWidth;
      let fontsize =(width * window.devicePixelRatio) / xs;
      if(fontsize > 1){
          fontsize = 1;
      }
      document
        .querySelector(":root")
        .setAttribute(
          "style",
          `font-size : ${ fontsize }px`
        );
        
      //helper.rootFontSize = width / xs;
      //console.log(helper.rootFontSize +',' + fontsize);
        helper.rootFontSize = fontsize;
      helper.width = width;
    };
    window.onresize();
  },
  data() {
    return {
      width: 1920,
    };
  },
  components: {},
  methods: {
    ClickOther($event) {
      // console.log(39)
      this.$EventBus.$emit("ClickOtherInToolDialog");
    },
  },
};
</script>

<style>
.border-box * {
  box-sizing: border-box;
}
:root {
  font-size: 1px;
}
body {
  margin: 0;
  background-color: #1a1a1a;
}

/* no-scroll用于不需要滑动的页面的最顶层节点 */
.no-scroll {
  /* height : 100%; */
  /* height: 100vh; */
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}
/*不可选中，拖拽*/ 
img,.no-select {
  -moz-user-select: none;
  -o-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}
input[type="text"],
[type="password"] {
  background: none;
  outline: none;
  border: 0px;
  font-size: 16rem;
  font-family: "GenSenMaruGothicTW-Bold";
  caret-color: #fff;
  color: #fff;
  width: 100%;
}
input[type="checkbox"] {
  padding: 0;
  margin: 0;
  width: 15rem;
  height: 15rem;
  border-radius: 2rem;
  cursor: pointer;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: 0 0 0 1000px #252525 inset;
  transition: background-color 5000s ease-in-out 0s;
}
.input {
  width: 390rem;
  height: 45rem;
  border: 2rem solid #4d4d4d;
  border-radius: 45rem;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  overflow: hidden;
}
.input-area {
  padding: 15rem 20rem;
}
.input:hover {
  border-color: #f93086;
}
.btn-confirm {
  width: 390rem;
  height: 54rem;
  border-radius: 30rem;
  background: #f93086;
  font-size: 15rem;
  font-family: "GenSenMaruGothicTW-Bold";
  letter-spacing: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
}
.btn-reg {
  width: 386rem;
  height: 41rem;
  border: 2rem solid #f93086;
  border-radius: 30rem;
  font-size: 15rem;
  font-family: "GenSenMaruGothicTW-Bold";
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f93086;
  cursor: pointer;
}
.btn-check {
  width: 390rem;
  height: 30rem;
  display: flex;
  align-items: center;
  font-family: "GenSenMaruGothicTW-Bold";
  font-size: 14rem;
  color: #c6c6c6;
  letter-spacing: 0;
  line-height: 30rem;
  font-weight: 700;
  cursor: pointer;
}
[v-cloak] {
  display: none;
}
.pf-toast {
  font-family: "GenSenMaruGothicTW-Bold";
}
.pf-toast .error {
    background: #A61D24;
}
.pf-toast .toasted-primary.success {
    background:#4d4d4d !important;
}
/* .toasted {
  background:#4d4d4d !important;
} */
a {
  text-decoration: none;
}
.disabled {
  background: #cdcdcd !important;
  color: #333333 !important;
  cursor: default !important;
}
</style>
