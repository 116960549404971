<template>
  <div class="border-box">
    <TopNavigator />
    <div v-if="version!==null">
      <div class="top">
            <div style="width:1200rem; margin:0 auto;">
                <div class="title">POWERFUN DOWNLOAD CENTER</div>
                <div class="buttons">
                <div :class="`btn no-select ${activeIndex===0 && 'btn-active'}`" @click="ScrollTo(505+0*600,0)" @mouseover="SetActive(0)" @mouseleave="SetActive(-1)">
                    <img v-if="activeIndex===0" src="@/assets/D-powerfun - 副本.png">
                    <img v-else src="@/assets/D-powerfun.png">
                    <div class="text">PowerFun</div>
                </div>
                <div :class="`btn no-select ${activeIndex===1 && 'btn-active'}`" @click="ScrollTo(505+1*600,1)" @mouseover="SetActive(1)" @mouseleave="SetActive(-1)">
                    <img v-if="activeIndex===1" src="@/assets/D-powerfun - 副本.png">
                    <img v-else src="@/assets/D-powerfun.png">
                    <div class="text">PowerFun Workouts</div>
                </div>
                <div :class="`btn no-select ${activeIndex===2 && 'btn-active'}`" @click="ScrollTo(505+2*600,2)" @mouseover="SetActive(2)" @mouseleave="SetActive(-1)">
                    <img v-if="activeIndex===2" src="@/assets/D-app store - 副本.png">
                    <img v-else src="@/assets/D-app store.png">
                    <div class="text">iOS</div>
                </div>
                <div :class="`btn no-select ${activeIndex===3 && 'btn-active'}`" @click="ScrollTo(505+3*600,3)" @mouseover="SetActive(3)" @mouseleave="SetActive(-1)">
                    <img v-if="activeIndex===3" src="@/assets/D-android2.png">
                    <img v-else src="@/assets/D-android.png">
                    <div class="text">Android</div>
                </div>
                <div :class="`btn no-select ${activeIndex===4 && 'btn-active'}`" @click="ScrollTo(505+4*600,4)" @mouseover="SetActive(4)" @mouseleave="SetActive(-1)">
                    <img v-if="activeIndex===4" src="@/assets/D-ant - 副本.png">
                    <img v-else src="@/assets/D-ant.png">
                    <div class="text">Ant+</div>
                </div>
                </div>
            </div>
      </div>
      <div class="content-pf">
        <div class="main">
          <img src="@/assets/download/download-powerfun.png" class="left-img-win">
          <!-- <div style="width:62rem"></div> -->
          <div class="right">
            <div class="title">PowerFun</div>
            <div class="sub-title">Download V{{version.Unity.Version}} for windows PC</div>
            <div class="line"></div>
            <div class="desc">Windows 10 ｜ CPU 2.33Ghz or faster, 4GB RAM (8GB recommended)</div>
            <div class="downloads">
              <!-- <img src="@/assets/download/windows.png" class="icon"> -->
              <a class="btn-download no-select" :href="version.Unity.Url"  target="_blank">
                <img src="@/assets/download/下载-windows.png" class="icon">
                <div class="text">Download</div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="content-pf" style="background:#252525">
        <div class="main">

          <div class="right">
            <div class="title">PowerFun Workouts</div>
            <div class="sub-title">Download V{{version.WPF.Version}} for windows PC</div>
            <div class="line"></div>
            <div class="desc">Windows 10 ｜ CPU 2.0Ghz or Faster, 2GB RAM (4GB Recommended)</div>
            <div class="downloads">
              <!-- <img src="@/assets/download/windows.png" class="icon"> -->
              <a :href="version.WPF.Url" class="btn-download no-select"  target="_blank">
                <img src="@/assets/download/下载-windows.png" class="icon">
                <div class="text">Download</div>
              </a>
            </div>
          </div>
          <!-- <div style="width:85rem"></div> -->
          <img src="@/assets/download/download-powerfun workouts.png" class="left-img-win">
        </div>
      </div>
      <div class="content-pf">
        <div class="main">
          <img src="@/assets/download/download-appstore.png" class="left-img-phone">
          <!-- <div style="width:90rem"></div> -->
          <div class="right">
            <div class="title">iOS</div>
            <div class="sub-title">Download V{{version.Ios.Version}} for iPhone</div>
            <div class="line"></div>
            <div class="desc">iOS 11.0+ with Bluetooth 4+ ｜ Only Bluetooth Smart communication supported.</div>
            <div class="downloads">
              <!-- <img src="@/assets/download/apple.png" class="icon"> -->
              <a class="btn-download no-select" :href="version.Ios.Url" target="_blank">
                <img src="@/assets/download/下载-苹果.png" class="icon">
                <div class="text">Download</div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="content-pf" style="background:#252525">
        <div class="main">
          <div class="right">
            <div class="title">Android</div>
            <div class="sub-title">Download V{{version.Apk.Version}} for Android</div>
            <div class="line"></div>
            <div class="desc">Android 5.0+ ｜ Only Bluetooth Smart communication supported.</div>
            <div class="downloads">
              <!-- <img src="@/assets/D-android2.png" class="icon"> -->
              <a class="btn-download no-select" :href="version.Apk.Url" target="_blank">
                <img src="@/assets/download/下载-安卓.png" class="icon">
                <div class="text">Download</div>
              </a>
              <a class="btn-download no-select"  style="margin-left:20rem; width:210rem;" :href="version.Apk.GooglePlayUrl" target="_blank">
                <img src="@/assets/download/下载-谷歌.png" class="icon" style="margin-left:36rem;">
                <div style="display:flex;flex-direction: column;">
                  <div style="font-family: 'GenSenMaruGothicTW-Medium'; font-size: 12rem;color: #ffffff;font-weight: 500;">GET IT ON</div>
                  <div class="text" style="margin-right:35rem;">Google Play</div>
                </div>
              </a>
            </div>
          </div>
          <div style="width:119rem"></div>
          <img src="@/assets/download/download-google play.png" class="left-img-phone">
        </div>
      </div>
      <div class="content-pf">
        <div class="main">
          <img src="@/assets/download/download-ant备份.png" class="left-img-ant">
          <div style="width:61rem"></div>
          <div class="right">
            <div class="title">Ant+ Adapter Driver</div>
            <div class="sub-title">Install the ant+ adapter driver for Windows.</div>
            <div class="line"></div>
            <div class="desc">Windows 10</div>
            <div class="downloads">
              <!-- <img src="@/assets/download/ant.png" class="icon"> -->
              <a class="btn-download no-select" href="http://www.thisisant.com/assets/resources/Software%20Tools/ant_usb2_drivers.zip" target="_blank">
                <img src="@/assets/download/下载-ant.png" class="icon">
                <div class="text">Download</div>
                <!-- <img src="@/assets/download/下载.png"> -->
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <BottomNavigator />
  </div>
</template>

<script>
import helper from "@/utils/helper";
import { Getversions } from "@/api/commonApi";
import TopNavigator from "@/components/TopNavigator";
import BottomNavigator from "@/components/BottomNavigator";
export default {
  name: "Download",
  data() {
    return {
      version: null,
      activeIndex:-1,
      googlePlayUrl:'https://play.google.com/store/apps/details?id=com.zhixingpai.powerfun',
    };
  },
  components: {
    TopNavigator,
    BottomNavigator,
  },
  created() {
    this.activeIndex = -1
    Getversions()
      .then((res) => {
        this.version = res.data;
      })
      .catch((ex) => {
        this.$toasted.show(ex, { type: "error" });
      });
  },
  mounted() {},
  methods: {
    ScrollTo(rem,activeIndex) {
      document.documentElement.scrollTop = helper.rootFontSize * rem;
      
    },
    SetActive(activeIndex){
      this.activeIndex = activeIndex
    },
    GoDownload(url) {
      window.location.href = url;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang='scss'>
.top {
  width: 100%;
  height: 422rem;
  background: url("../../assets/Photo 1.png");
  padding: 112rem 0 0rem 0rem;
  .title {
    // width: 708rem;
    height: 78rem;
    font-family: "GenSenMaruGothicTW-Bold";
    font-size: 52rem;
    color: #ffffff;
    line-height: 78rem;
    font-weight: 700;
  }
  .buttons {
    display: flex;
    margin-top: 5rem;
    .btn {
      height: 44rem;
      padding: 0 20rem;
      display: flex;
      align-items: center;
      margin-right: 10rem;
      font-family: "GenSenMaruGothicTW-Bold";
      font-size: 16rem;
      color: #1a1a1a;
      font-weight: 700;
      border-radius: 22rem;
      background-color: #ffffff;
      cursor: pointer;
      img {
        width: 20rem;
        height: auto;
      }
      .text {
        margin-left: 10rem;
      }
    }
    .btn-active{
      color: #fff;
      background-color: #f93086;
    }
  }
}
.btn-download {
  width: 196rem;
  height: 54rem;
  background: #f93086;
  border-radius: 27rem;
  //padding: 0 32rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  .text {
    width: 100%;
    font-family: 'GenSenMaruGothicTW-Medium';
    font-size: 18rem;
    color: #ffffff;
    font-weight: 700;
  }
  img {
    width: 26rem;
    height: 26rem;
  }
}
.left-img-win {
  width: 670rem;
  height: 509rem;
}
.left-img-phone {
  width: 640rem;
  height: 485rem;
}
.left-img-ant {
  width: 669rem;
  height: 385rem;
}
.content-pf {
  width: 100%;
  height: 600rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 46rem;
  padding-bottom: 45rem;
  .main {
    display: flex;
    align-items: center;
    width:1200rem;
    margin:0 auto;
    justify-content: space-between;

    .right {
      .title {
        // width: 203rem;
        height: 58rem;
        font-family: "GenSenMaruGothicTW-Bold";
        font-size: 42rem;
        color: #ffffff;
        font-weight: 700;
      }
      .sub-title {
        width: 440rem;
        height: 32rem;
        font-family: "GenSenMaruGothicTW-Medium";
        font-size: 18rem;
        color: #ffffff;
        line-height: 32rem;
        font-weight: 500;
      }
      .line {
        margin-top: 8.5rem;
        width: 440rem;
        height: 1rem;
        background: #444444;
      }
      .desc {
        margin-top: 13.5rem;
        width: 440rem;
        height: 80rem;
        font-family: "GenSenMaruGothicTW-Medium";
        font-size: 16rem;
        color: #a6a6a6;
        line-height: 20rem;
        font-weight: 500;
      }
      .downloads {
        display: flex;
        align-items: center;
        margin-top: 29rem;
        .icon {
          width: 30rem;
          height: 30rem;
          margin: 10rem 10rem 14rem 32rem;
        }
      }
    }
  }
}
</style>
