export default {
    width: 1920,
    rootFontSize:1,
    routeList: [
        {
            name: "Index",
            display: "Home",
            path:'/',
            current: false,
        },
        {
            name: "Routes",
            display: "Routes",
            path:'/Routes',
            current: false,
        },
        {
            name: "Download",
            display: "Download",
            path:'/Download',
            current: false,
        },
        {
            name: "FAQ",
            display: "FAQ",
            path:'/About',
            current: false,
        },
        // {
        //     name: "Mine",
        //     display: "My Center",
        //     current: false,
        // },
    ],
    GoPage: (context, name) => {
        if (!context.$router.options.routes.find((x) => x.name === name)) {
            context.$toasted.show("暂未开放", {
                type: "error",
            });
            // alert("暂未开放");
            return;
        }
        if (name === context.routeName) {
            return;
        }
        context.$router.replace({ name: name });
    },
}