import Index from '@/views/Index/Index.vue'
import Sign from '@/views/Sign/Sign.vue'
import Routes from '@/views/Routes/Routes.vue'
import About from '@/views/About/About.vue'
import Download from '@/views/Download/Download.vue'
import Mine from '@/views/Mine/Mine.vue'
import Upload from '@/views/Mine/Upload.vue'
import Privacy from '@/views/Static/Privacy.vue'
import TermSevice from '@/views/Static/TermSevice.vue'
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    { name: "Index", path: '/', component: Index },
    { name: "Sign", path: '/Sign', component: Sign },
    { name: "Routes", path: '/Routes', component: Routes },
    { name: "Privacy", path: '/Privacy', component: Privacy },
    { name: "TermSevice", path: '/TermSevice', component: TermSevice },
    {
        name: 'ResetPwd',
        path: '/resetpwd',
        component: () => import('@/views/Sign/ResetPwd.vue')
      },
    { name: "About", path: '/About', 
        component: {
            render(c){
                return c('router-view');
            }
        },
        redirect:'/About/Index',
        children:[
            {
                name:'FAQ-Index',
                path:'Index',
                component:About
            },
            {
                name:'FAQ-HowToStart',
                path:'HowToStart',
                component: ()=> import('@/views/About/HowToStart.vue')
            }
        ]
    },
    { name: "Download", path: '/Download', component: Download },
    
    {
        path: '/Mine',
        name: 'Mine',
        component: {
            render(c){
                return c('router-view');
            }
        },
        redirect:'/Mine/Index',
        children: [
            {
                path: 'Index',
                component:Mine,
            },
            {
                path: 'Upload',
                component:Upload,
            },
        ]
    },
    {
        name: '404',
        path: '/404',
        component: () => import('@/views/NotFound.vue')
      },
    {
        path: '*', // 页面不存在的情况下会跳到404页面
        redirect: '/404',
        name: 'NotFound',
        hidden: true
    }
]
const router = new VueRouter({
    routes,
    mode: 'hash'
})
export default router
