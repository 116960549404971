<template>
    <div>
        <TopNavigator />
        <div class="wrap">
            <div>
                <div class="title">TERMS OF SERVICE</div>
                <div class="content">
                    <p><span>Welcome to PowerFun.com and Related Apps PowerFun Desktop, Mobile and Tablet Apps, PowerFun, PowerFun Workouts, (collectively referred to as &quot;PowerFun&quot;,"We" or "Our").</span></p>
                    
                    <p><span>Powerfun</span><span>, related mobile applications and services (collectively, the &ldquo;Services&rdquo;) are made available to you by VirtualTraining s.r.o. Address: 109 Highway, Jingkai District, Wuxi City, Jiangsu Province,&nbsp;</span><span style="font-family:宋体;font-size:14px;">Chinese people republic</span><span>, subject to these Terms of Service (the &ldquo;Terms&rdquo;) and in accordance with the&nbsp;</span>Powerfun<span>&nbsp;Privacy Policy (the &ldquo;Privacy Policy&rdquo;) and&nbsp;</span>Powerfun<span>&nbsp;Legal Cookie Statement. More information about the Services may be found at https://www.powerfun.com. These terms and conditions and all services provided are rendered to you under and in accordance with Chine.</span></p>
                    
                    <p><span class="title">Agreement to Powerfun Terms and Conditions of Use</span></p>
                    <div class="sub-content">
                        <p><span>Subscribers, customers, users, third parties acting on behalf of users, or others who download, use, purchase, subscribe, aggregate&nbsp;</span>Powerfun<span>&nbsp;data, information, or Content available on or via&nbsp;</span>Powerfun<span>&nbsp;affiliated websites, or otherwise interface with or access the&nbsp;</span>Powerfun<span>&nbsp;affiliated websites or Services (collectively or individually &ldquo;You&rdquo; &ldquo;User&rdquo; or &ldquo;Users&rdquo;) must do so under the following&nbsp;</span>Powerfun<span>&nbsp;Terms and Conditions of Use (&ldquo;Terms&rdquo;).</span></p>
                        
                        <p><span>BY ACCESSING, USING OR UPLOADING OR DOWNLOADING ANY INFORMATION OR MATERIALS TO OR FROM THE SERVICES, OR BY INDICATING YOUR ASSENT TO THESE TERMS BY CREATING AN ACCOUNT, CLICKING &ldquo;SIGN UP&rdquo; OR ANY SIMILAR MECHANISM, YOU ARE AGREEING TO THE THESE TERMS. IF YOU DO NOT AGREE TO THE THESE TERMS, DO NOT ACCESS OR USE THE SERVICES.</span></p>
                        
                        <p><span>BY USING THE SERVICES, A USER THEREFORE AGREES, REPRESENTS AND WARRANTS THAT:</span></p>
                        
                        <p><span>he or she has received consent from his or her physician to participate in ANY programs, workouts, exercises, OR ANY OTHER FITNESS OR HEALTH RELATED ACTIVITY provided by&nbsp;</span>Powerfun<span>&nbsp;or displayed AS CONTENT on its affiliated websites.</span></p>
                        
                        <p><span>If you access or use the Services on behalf of a company or other entity, you represent that you have authority to bind such entity and its affiliates to these Terms and that it is fully binding on them. In such case, the term &ldquo;you&rdquo; will refer to such entity and its affiliates. If you do not have authority, you may not access or use the Services. These Terms contain disclaimers of warranties and limitations on liability that may be applicable to you.</span></p>
                        
                        <p><span>The Services cannot be provided and the agreement described in these Terms cannot be performed without&nbsp;</span>Powerfun<span>&nbsp;processing data about you, and other&nbsp;</span>Powerfun<span>&nbsp;members, including your location data. Processing of the data you share with&nbsp;</span>Powerfun<span>, including location data, is essential to the Services which we provide and a necessary part of our performance of the agreement we have with you.</span></p>
                        
                    </div>
                    <p><span class="title">Privacy Policy</span></p>
                    <div class="sub-content">
                        <p><span>Powerfun&rsquo;s Privacy Policy is hereby incorporated into the Agreement by reference. Please read this notice carefully for disclosures relating to&nbsp;</span>Powerfun<span>&rsquo;s security protocols, and more importantly, the collection, use, and disclosure of your personal information and geographic location information. Click here to review the&nbsp;</span>Powerfun<span>&nbsp;Privacy Policy.</span></p>
                        
                        <p><span class="title">Ownership and Proprietary Rights</span></p>
                        <p><span>The Services are owned and operated by&nbsp;</span>Powerfun<span>. The&nbsp;</span>Powerfun<span>&nbsp;software, content, visual interfaces, information, graphics, design, compilation, computer code, online platform, products, software, services, including, but not limited to, the mobile device applications, and all other elements of the Services (collectively, the &ldquo;Materials&rdquo;) are protected by Czech Republic copyright, trade dress, patent, and trademark laws, international laws and conventions, and all other relevant intellectual property and proprietary rights, and applicable laws. All materials and components contained in the Services are the property of&nbsp;</span>Powerfun<span>&nbsp;or its subsidiaries or affiliated companies and/or third-party licensors. All trademarks, service marks, and trade names displayed on the Services are proprietary to&nbsp;</span>Powerfun<span>&nbsp;or its affiliates and/or third-party licensors. Except as expressly authorized by&nbsp;</span>Powerfun<span>, any and all Users agree not to sell, license, distribute, copy, modify, publicly perform or display, transmit, publish, edit, adapt, create derivative works from, or otherwise make unauthorized use of the Materials.</span></p>
                        
                        <p><span class="title">Account Information</span></p>
                        <p><span>The Services are intended solely for persons who are 13 years old or such higher age required in your country to use the Services. In order to access certain functionality of the Services, such as that relating to health data, you must be at least 16 years old. Users agree that the information that they provide&nbsp;</span>Powerfun<span>&nbsp;upon registration and at all other times will be true, accurate, current, and complete. Users also agree that they will ensure that this information is kept accurate and up to date at all times.</span></p>
                        
                        <p><span>You may register for or log-in to your account via a third-party network, such as Facebook or TrainingPeaks. If you do so, you hereby authorize&nbsp;</span>Powerfun<span>&nbsp;to pre-populate the registration and other relevant information fields of your account and/or to use such third-party credentials to log you into your account. If you connect your account to a third-party network, you agree to comply with the terms and conditions and policies applicable to such third party.</span></p>
                        
                        <p><span>By virtue of certain of the Services connecting to the Google Maps API, you hereby agree to be bound by the Google Maps/Google Earth Additional Terms of Service (including the Google Privacy Policy) in connection with your use of such Services.</span></p>
                        
                    </div>
                    <p><span class="title">Password</span></p>
                    <div class="sub-content">
                        <p><span>When a User registers as a member and obtains an account, he or she will be asked to provide a password. Because a User will be responsible for all activities that generate from his or her account, and one can only access his or her account upon the transmission of the associated password, a User should keep that password strictly confidential at all times. A USER MUST NOTIFY&nbsp;</span>Powerfun<span>&nbsp;IMMEDIATELY OF ANY UNAUTHORIZED USE OF HIS OR HER PASSWORD OR IF HE OR SHE BELIEVES THAT PASSWORD IS NO LONGER CONFIDENTIAL.&nbsp;</span>Powerfun<span>&nbsp;reserves the right to suspend a User&rsquo;s account and/or require that such User alter his or her password if we believe for any reason that such User&rsquo;s password is no longer secure. A USER MAY NOT SHARE HIS OR HER&nbsp;</span>Powerfun<span>&nbsp;ACCOUNT PASSWORD WITH ANY OTHER PERSON FOR ANY REASON.</span></p>
                    </div>
                    <p><span class="title">Payment Terms and Cancellations</span></p>
                    <div class="sub-content">
                        <p><span class="sub-title">1.Payments Terms</span></p>
                        <p><span>The User agrees to pay any fees due for and incurred by subscribing to any of our enhanced Services that require payment on the part of Users (&ldquo;Paid Services&rdquo;). To pay any fee, you must designate and provide information about your preferred payment method (e.g., credit card, online payment service, a third party, like iTunes or Google Play, or any other payment method made available by&nbsp;</span>Powerfun<span>) (the &ldquo;Payment Method&rdquo;). If you provide your payment information, you authorize us and certain third party service providers, payment card networks and payment processors to receive, store and encrypt your payment information. You may switch to a different Payment Method or update your information by visiting https://powerfun.com. If you signed up through the Google Play or iTunes store, you may change your Payment Method through the corresponding store account. No refunds or credits will be provided by&nbsp;</span>Powerfun<span>, other than as set forth in these Terms. If your payment provider determines that there are fraudulent charges on your account resulting from use of your Payment Method at the Services, please contact us immediately via https://www.powerfun.com.</span></p>
                        <p><span class="title">Mobile Services</span></p>
                        <p><span class="sub-title">1.Wireless Carrier and Device Considerations</span></p>
                        <p><span>To use or access our applications, you will need a compatible device. We cannot guarantee the applications will be compatible with, or available on, your device. We do not charge for use of some basic applications; however, you may need to pay fees to use certain premium applications or features. Further, your phone company&apos;s normal messaging, data, and other rates and fees will still apply.</span></p>
                
                        <p><span class="sub-title">2.Text and Mobile Messaging Express Consents</span></p>
                        <p><span>By downloading or using our applications, you expressly agree we may communicate with you regarding transactions you have initiated on the Services or respond to your communications to us through the Services by SMS, MMS, text message, or other electronic means directed to your device and that certain information about your usage of the applications may be communicated to us automatically form your device. We will not send you direct messages of a marketing nature without your prior express written consent and you can opt out of receiving any marketing messages from us at any time.</span></p>
                        
                        <p><span class="sub-title">3.Mobile Application License</span></p>
                        <p><span>We hereby grant you a limited, personal, non-exclusive, non-transferable, non-sublicensable, revocable license to use our applications downloaded directly from a legitimate marketplace or directly on https://powerfun.com, solely in object code format and solely for your personal use for lawful purposes. With respect to any open source or third-party code that may be incorporated in the applications, such open source code is covered by the applicable open source or third-party license EULA, if any, authorizing use of such code.</span></p>
                        
                        <p><span class="sub-title">4.App Stores</span></p>
                        <p><span>If you download our applications from a third-party app store (the &quot;App Provider&quot;), you acknowledge and agree that:</span></p>
                        
                        <p><span>The Terms are an agreement between us, and not with the App Provider. As between&nbsp;</span>Powerfun<span>&nbsp;and the App Provider,&nbsp;</span>Powerfun<span>&nbsp;is solely responsible for its applications</span></p>
                        <p><span>The App Provider has no obligation to provide any maintenance and support services with respect to the&nbsp;</span>Powerfun<span>&nbsp;applications</span></p>
                        <p><span>In the event of any failure of the&nbsp;</span>Powerfun<span>&nbsp;applications to conform to any applicable warranty, (i) you may notify the App Provider and the App Provider may refund the purchase price for the applications to you (if applicable), (ii) to the maximum extent permitted by applicable law, the App Provider will have no other warranty obligation whatsoever with respect to the applications, and (iii) any other claims, losses, liabilities, damages, costs or expenses attributable to any failure to conform to any warranty will be, as between&nbsp;</span>Powerfun<span>&nbsp;and the App Provider,&nbsp;</span>Powerfun<span>&apos;s responsibility</span></p>
                        <p><span>The App Provider is not responsible for addressing any claims you have relating to the applications or your possession and use of the applications</span></p>
                        <p><span>vIf a third party claims an application infringes another party&apos;s intellectual property rights, as between the App Provider and&nbsp;</span>Powerfun<span>,&nbsp;</span>Powerfun<span>&nbsp;will be responsible for the investigation, defense, settlement and discharge of any such claim to the extent required by these Terms</span></p>
                        
                        <p><span>The App Provider and its subsidiaries are third-party beneficiaries of these Terms as it relates to your license to the applications. Upon your acceptance of these Terms, the App Provider will have the right (and will be deemed to have accepted the right) to enforce these Terms as related to your license of the applications against you as a third-party beneficiary thereof and</span></p>
                        <p><span>You must also comply with all applicable third-party terms of service when using the applications.</span></p>
                    </div>
                    <p><span class="title">Powerfun Usage Rules</span></p>
                    <div class="sub-content">
                        <p><span class="sub-title">1.Powerfun Usage Rules Prohibited conduct &amp; Uses</span></p>
                        <p><span>A USER UNDERSTANDS AND HEREBY ACKNOWLEDGES AND AGREES THAT HE OR SHE MAY NOT, AND WARRANT THAT HER OR SHE WILL NOT:</span></p>

                        <p><span>use the Services or any personal information or location information displayed on or within&nbsp;</span>Powerfun<span>&nbsp;to &ldquo;stalk,&rdquo; harass, abuse, defame, threaten or defraud other Users, or collect, attempt to collect, or store location information or personal information about other Users.</span></p>
                        <p><span>include offensive or pornographic materials on or in his or her&nbsp;</span>Powerfun<span>&nbsp;personal profile pages.</span></p>
                        <p><span>use&nbsp;</span>Powerfun<span>&nbsp;or the Services for any commercial or non-private use, it being understood that&nbsp;</span>Powerfun<span>&nbsp;and the Services are to be employed for personal, non-commercial use only.</span></p>
                        <p><span>use&nbsp;</span>Powerfun<span>&nbsp;or the Services for any illegal purpose, or in violation of any local, state, national, or international law, including, without limitation, laws governing intellectual property and other proprietary rights, data protection and privacy, and import or export control.</span></p>
                        <p><span>make unsolicited offers, advertisements, proposals, or send junk mail, to other Users of&nbsp;</span>Powerfun<span>. This includes, but is not limited to, unsolicited advertising, promotional materials or other solicitation material, bulk mailing of commercial advertising, chain mail, informational announcements, charity requests, and petitions for signatures.</span></p>
                        <p><span>impersonate any person or entity, falsely claim an affiliation with any person or entity, or access the&nbsp;</span>Powerfun<span>&nbsp;accounts of other Users.</span></p>
                        <p><span>share&nbsp;</span>Powerfun<span>-issued passwords or any other means of access to a mobile device while any Service is running and accessible with any third party or encourage any other User(s) to do so.</span></p>
                        <p><span>misrepresent the source, identity or content of information transmitted via&nbsp;</span>Powerfun.</p>
                        <p><span>emove, circumvent, disable, damage or otherwise interfere with: (i) the security-related features of&nbsp;</span>Powerfun<span>&nbsp;or the Services; (ii) the features of&nbsp;</span>Powerfun<span>&nbsp;and the Services that prevent or restrict use or copying of any content accessible through the&nbsp;</span>Powerfun<span>; or (iii) the features of&nbsp;</span>Powerfun<span>&nbsp;and the Services that enforce limitations on use of the Services.</span></p>
                        <p><span>intentionally interfere with, or damage operation of&nbsp;</span>Powerfun<span>&nbsp;or the Services, or any User&rsquo;s enjoyment of them, by any means, including uploading or otherwise disseminating viruses, worms, or other malicious code.</span></p>
                        <p><span>post, store, send, transmit, or disseminate any information or material which a reasonable person could deem to be objectionable, libelous, offensive, indecent, pornographic, harassing, threatening, embarrassing, distressing, vulgar, hateful, racially or ethnically offensive, or otherwise inappropriate, regardless of whether this material or its dissemination is unlawful.</span></p>
                        <p><span>post, store, send, transmit, or disseminate any information or material which infringes any patents, trademarks, trade secrets, copyrights, or any other proprietary or intellectual property right of the a third party.</span></p>
                        <p><span>attempt to gain unauthorized access to&nbsp;</span>Powerfun<span>&nbsp;or the Services, other accounts, computer systems or networks connected to&nbsp;</span>Powerfun<span>, or any part of it, through hacking, password mining, or any other means to interfere with, or attempt to interfere with the proper working of&nbsp;</span>Powerfun<span>&nbsp;or any activities conducted on&nbsp;</span>Powerfun.</p>
                        <p><span>use any robot, spider, scraper or other automated means to access&nbsp;</span>Powerfun<span>&nbsp;for any purpose without&nbsp;</span>Powerfun<span>&rsquo;s express written permission or bypass&nbsp;</span>Powerfun<span>&rsquo;s robot exclusion headers or other measures we may use to prevent or restrict access to&nbsp;</span>Powerfun<span>&nbsp;or modify&nbsp;</span>Powerfun<span>&nbsp;in any manner or form, nor to use modified versions of&nbsp;</span>Powerfun<span>, including (without limitation) for the purpose of obtaining unauthorized access to&nbsp;</span>Powerfun.</p>
                        <p><span>sell or transfer or allow another person to access his or her account passwords, profiles, or&nbsp;</span>Powerfun<span>&nbsp;accounts.</span></p>
                    </div>
                    <p><span class="title">User Content</span></p>
                    <div class="sub-content">
                        <p><span>Any and all information (other than personal information and/or location information which are expressly covered and governed by the&nbsp;</span>Powerfun<span>&nbsp;Privacy Policy), including data, text, video, images, files, geo-mapped routes, graphics and any and all other material that a User transmits to, or supplies to&nbsp;</span>Powerfun<span>&nbsp;is referred to as &ldquo;User Content.&rdquo; By transmitting User Content on or through&nbsp;</span>Powerfun<span>&nbsp;and its affiliated websites a User expressly warrants:</span></p>
                        
                        <p><span>he or she is solely responsible for the transmission, accuracy, completeness, and publication of that User Content.</span></p>
                        <p><span>he or she controls all of the rights to that User Content and that it neither does nor will infringe or violate the rights of any third party.</span></p>
                        <p>Powerfun<span>&nbsp;bears no responsibility, legal or otherwise, for that User Content.</span></p>
                        <p><span class="sub-title">1.Prohibited User Content</span></p>
                        <p><span>A User may not post Content on&nbsp;</span>Powerfun<span>.com that:</span></p>
                        
                        <p><span>is patently offensive and/or promotes racism, bigotry, hatred or physical harm of any kind against any group or individual;</span></p>
                        <p><span>harasses or advocates harassment of another person;</span></p>
                        <p><span>exploits people in a sexual or violent manner;</span></p>
                        <p><span>contains nudity, violence, or offensive subject matter;</span></p>
                        <p><span>provides any telephone numbers, street addresses, last names or email addresses of anyone except said User;</span></p>
                        <p><span>promotes information that a User knows is false or misleading or promotes illegal activities or conduct that is abusive, threatening, obscene, defamatory or libelous;</span></p>
                        <p><span>violates any intellectual property or other proprietary right of any third party, including User Content that promotes an illegal or unauthorized copy of another person&rsquo;s copyrighted work, such as providing pirated computer programs or links to them, providing information to circumvent manufacture-installed copy-protect devices, or providing pirated music or links to pirated music files;</span></p>
                        <p><span>involves the transmission of &ldquo;junk mail,&rdquo; &ldquo;chain letters,&rdquo; or unsolicited mass mailing, instant messaging, &ldquo;spimming,&rdquo; or &ldquo;spamming&rdquo;;</span></p>
                        <p><span>furthers or promotes any criminal activity or enterprise or provides instructional information about illegal activities including, but not limited to making or buying illegal weapons, violating someone&rsquo;s privacy, or providing or creating computer viruses;</span></p>
                        <p><span>solicits passwords or personal identifying information for commercial or unlawful purposes from other members;</span></p>
                        <p><span>involves commercial activities and/or sales without&nbsp;</span>Powerfun<span>&rsquo;s prior written consent, such as contests, sweepstakes, barter, advertising, or pyramid schemes;</span></p>
                        <p><span>includes a photograph of another person posted without that person&rsquo;s consent or, in the case of children under the age of eighteen (18), parental consent, or otherwise constitutes an invasion of an individual&rsquo;s privacy or infringement of publicity rights; or</span></p>
                        <p><span>contains a virus or other harmful component.</span></p>
                        <p><span class="sub-title">2.Ownership and Use of Content</span></p>
                        <p><span>A User is responsible for all User Content posted under his or her account on&nbsp;</span>Powerfun<span>, and for adjusting those account settings, specifically User Privacy Settings, that permit him or her to display User Content on&nbsp;</span>Powerfun<span>.</span></p>
                        
                        <p><span>Posting User Content via the Internet inherently poses the risk of unintended disclosure and access by third parties to said User Content.&nbsp;</span>Powerfun<span>&nbsp;will make reasonable efforts to prevent User Content from disclosure that exceeds the account settings selected by a User, but&nbsp;</span>Powerfun<span>&nbsp;cannot guarantee that User account settings will completely protect against some User Content from being viewed or accessed by unintended third parties, and&nbsp;</span>Powerfun<span>&nbsp;will not be liable for such chance disclosures.</span></p>
                        
                        <p><span class="sub-title">3.Ownership Rights In User Content</span></p>
                        <p><span>When a User transmits or otherwise supplies User Content to&nbsp;</span>Powerfun<span>, or otherwise makes User Content available on or through&nbsp;</span>Powerfun<span>, he or she expressly grants&nbsp;</span>Powerfun<span>&nbsp;a royalty-free, world-wide, perpetual, irrevocable, non-exclusive and fully sub-licensable right and license to use, reproduce, modify, adapt, publish, display, translate, or distribute such User Content for any purpose, commercial, advertising, or otherwise, on or in connection with&nbsp;</span>Powerfun<span>&nbsp;or the promotion thereof.</span></p>
                        
                        <p><span>In addition,&nbsp;</span>Powerfun<span>&nbsp;obtains a royalty-free, world-wide, perpetual, irrevocable, non-exclusive and fully sub-licensable right and license to create derivative works from User Content, display User Content, in whole or in part, and/or to incorporate User Content in other works in any form, media or technology, and a User agrees that any &ldquo;moral rights&rdquo; in that User Content have been waived. Moreover, when a User posts User Content to&nbsp;</span>Powerfun<span>, he or she authorizes and directs&nbsp;</span>Powerfun<span>&nbsp;to make such copies thereof as it deems necessary in order to facilitate the posting and storage of the User Content.</span></p>
                        
                        <p><span>The rights held by&nbsp;</span>Powerfun<span>&nbsp;relative to User Content, as delineated above, are, however, subject to the limitation that should&nbsp;</span>Powerfun<span>&nbsp;seek to make use of certain User Content&ndash;namely a User&rsquo;s image&ndash;for explicit commercial or advertising purposes,&nbsp;</span>Powerfun<span>&nbsp;will make a reasonable effort to secure consent from that User for that specific purpose.</span></p>
                        
                        <p><span>Further,&nbsp;</span>Powerfun<span>&nbsp;does not assert any real ownership over User Content. Rather, subject to the rights granted to&nbsp;</span>Powerfun<span>&nbsp;in these Terms, a User retains full ownership of any and all of his or her User Content and any intellectual property rights or other proprietary rights associated with said User Content.</span></p>
                        
                        <p><span class="sub-title">4.Ownership Of Powerfun Content</span></p>
                        <p>Powerfun<span>&nbsp;is the sole owner of all&nbsp;</span>Powerfun<span>&nbsp;Content including all copyrights, trademarks, trade dress, service marks, and other intellectual property rights. A User may not download, copy, or save any&nbsp;</span>Powerfun<span>&nbsp;Content or any portion of it, for any purpose, except as permitted by select Services (as provided for in the specific Guidelines and Policies relative to those Services), and in the limited cases where a User needs to print a copy of individual screens appearing as part of&nbsp;</span>Powerfun<span>&nbsp;solely for personal use or records, provided that any logos, marks or other legends that appear on the copied screens remain and are not removed from the printed copy. Except as expressly permitted under these Terms and/or certain ancillary Guidelines related to specific Services, a User may not modify, copy, publish, display, transmit, adapt or in any way exploit the&nbsp;</span>Powerfun<span>&nbsp;Content without the prior written permission of&nbsp;</span>Powerfun<span>.</span></p>
                        
                        <p><span class="sub-title">5.Content Retention</span></p>
                        <p><span>Following termination of your account, or if you remove any User-Generated Content from the Services, we may retain your User-Generated Content for a commercially reasonable period of time for backup, archival, or audit purposes, or as otherwise required or permitted by law. Furthermore,&nbsp;</span>Powerfun<span>&nbsp;and its users may retain and continue to use, store, display, reproduce, share, modify, create derivative works, perform, and distribute any of your User-Generated Content that otherwise has been stored or shared through the Services. Accordingly, note that the above license to your User-Generated Content continues even if you stop using the Services. When you post something publicly, others may choose to comment on it, making your Content part of a social conversation. For more information, please review our Privacy Policy.</span></p>

                        <p><span class="sub-title">6.Content Is Not Medical Advice</span></p>
                        <p>Powerfun<span>&nbsp;CONTENT DOES NOT CONSTITUTE MEDICAL ADVICE. SHOULD A USER HAVE ANY HEALTHCARE RELATED QUESTIONS, HE OR SHE SHOULD CONTACT HIS OR HER PHYSICIAN OR OTHER HEALTHCARE PROVIDER PROMPTLY AND IN ADVANCE OF HIS OR HER USE OF&nbsp;</span>Powerfun<span>&nbsp;AND THE SERVICES.</span></p>

                        <p><span>ANY&nbsp;</span>Powerfun<span>&nbsp;CONTENT, INCLUDING ALL TEXT, PHOTOGRAPHS, IMAGES, ILLUSTRATIONS, GRAPHICS, AUDIO, VIDEO AND AUDIO-VIDEO CLIPS, AND OTHER MATERIALS, WHETHER PROVIDED BY&nbsp;</span>Powerfun<span>&nbsp;OR BY OTHER USERS OR THIRD PARTIES IS NOT INTENDED TO BE AND SHOULD NOT BE USED IN PLACE OF (A) THE ADVICE OF A PHYSICIAN OR OTHER MEDICAL PROFESSIONAL, (B) A VISIT, CALL OR CONSULTATION WITH A PHYSICIAN OR OTHER MEDICAL PROFESSIONAL, OR (C) INFORMATION CONTAINED ON OR IN ANY PRODUCT PACKAGING OR LABEL.</span></p>

                        <p><span>A USER THEREFORE SHOULD NEVER DISREGARD MEDICAL ADVICE OR DELAY IN SEEKING MEDICAL ADVICE BECAUSE OF ANY&nbsp;</span>Powerfun<span>&nbsp;CONTENT AND A USER SHOULD NOT EMPLOY CONTENT FOR DIAGNOSING OR TREATING A HEALTH PROBLEM.</span></p>

                        <p><span>FURTHER, THE TRANSMISSION AND RECEIPT OF&nbsp;</span>Powerfun<span>&nbsp;CONTENT, IN WHOLE OR IN PART, OR COMMUNICATION VIA THE INTERNET, E-MAIL OR OTHER MEANS DOES NOT CONSTITUTE OR CREATE A DOCTOR-PATIENT, THERAPIST-PATIENT OR OTHER HEALTHCARE PROFESSIONAL RELATIONSHIP BETWEEN&nbsp;</span>Powerfun<span>&nbsp;AND A USER.</span></p>
                    </div>
                    <p><span class="title">No Warranties</span></p>
                    <div class="sub-content">
                        <p><span>THE SERVICES, INCLUDING THE MOBILE SERVICES, AND ANY THIRD-PARTY SOFTWARE, SERVICES, OR APPLICATIONS MADE AVAILABLE IN CONJUNCTION WITH OR THROUGH&nbsp;</span>Powerfun<span>&nbsp;ARE PROVIDED &ldquo;AS IS&rdquo; AND WITHOUT WARRANTIES OF ANY KIND EITHER EXPRESS OR IMPLIED.&nbsp;</span>Powerfun<span>, AND ITS SUPPLIERS AND PARTNERS (INCLUDING WITHOUT LIMITATION&nbsp;</span>Powerfun<span>&rsquo;S THIRD-PARTY WIRELESS CARRIER PARTNERS), DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT OF PROPRIETARY RIGHTS.</span></p>
                        
                        <p>Powerfun<span>&nbsp;AND ITS SUPPLIERS AND PARTNERS (INCLUDING WITHOUT LIMITATION&nbsp;</span>Powerfun<span>&rsquo;S THIRD-PARTY WIRELESS CARRIER PARTNERS) DO NOT WARRANT THAT THE FUNCTIONS CONTAINED IN THE MOBILE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT&nbsp;</span>Powerfun<span>&nbsp;OR THE SERVER THAT MAKES THEM AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. MOREOVER,&nbsp;</span>Powerfun<span>, AND ITS SUPPLIERS AND PARTNERS (INCLUDING WITHOUT LIMITATION&nbsp;</span>Powerfun<span>&rsquo;S THIRD-PARTY WIRELESS CARRIER PARTNERS) DO NOT WARRANT OR MAKE ANY REPRESENTATIONS REGARDING THE USE OR THE RESULTS OF THE USE OF ANY PERSONAL INFORMATION OR LOCATION INFORMATION OR THE SERVICES IN TERMS OF SECURITY, SAFETY, CORRECTNESS, ACCURACY, RELIABILITY, OR OTHERWISE. A USER (AND NOT&nbsp;</span>Powerfun<span>&nbsp;OR ITS SUPPLIERS OR PARTNERS) ASSUME THE ENTIRE COST OF ANY NECESSARY SERVICING, REPAIR, OR CORRECTION RELATED TO THE SERVICES.</span></p>
                        
                        <p><span class="title">Indemnification</span></p>
                        <p><span>A User agrees to indemnify, defend, and hold&nbsp;</span>Powerfun<span>, and its suppliers and partners (including, without limitation,&nbsp;</span>Powerfun<span>&rsquo;s wireless carrier partners) harmless from any and all claims, suits, actions, losses, costs, damages, and any other liabilities, including attorneys&rsquo; fees, arising out of or related to (a) the use or misuse of any User&rsquo;s personal information and location information, or the Services generally, (b) any violation of the rights of any other person or entity by User, (c) any breach or violation by a User of&nbsp;</span>Powerfun<span>, or (d) a User&rsquo;s employment of the Services to meet another User in-person or to locate and attend any offline place or event.</span></p>
                        
                        <p>Powerfun<span>&nbsp;reserves the right, at a given User&rsquo;s expense, to assume the exclusive defense and control of any matter for which such User is required to indemnify&nbsp;</span>Powerfun<span>, and said User agrees to cooperate with&nbsp;</span>Powerfun<span>&rsquo;s defense of these claims.</span></p>
                    </div>
                    <p><span class="title">Limitation of Liability and Damages</span></p>
                    <div class="sub-content">
                        <p><span>A USER ACKNOWLEDGES AND AGREES THAT UNDER NO CIRCUMSTANCES, INCLUDING, WITHOUT LIMITATION, NEGLIGENCE, WILL&nbsp;</span>Powerfun<span>, CONTRACTORS, EMPLOYEES, AGENTS, OR THIRD-PARTY PARTNERS (INCLUDING, WITHOUT LIMITATION,&nbsp;</span>Powerfun<span>&rsquo;S WIRELESS CARRIER PARTNERS) OR SUPPLIERS BE LIABLE TO ANY USER FOR ANY SPECIAL, INDIRECT, INCIDENTAL, PUNITIVE, RELIANCE, CONSEQUENTIAL, OR EXEMPLARY DAMAGES RELATED TO OR RESULTING FROM: (A) THE USE, DISCLOSURE, DISPLAY, OR MAINTENANCE OF A USER&rsquo;S PERSONAL INFORMATION OR LOCATION INFORMATION; (B) A USER&rsquo;S ABILITY OR INABILITY TO USE THE SERVICES; (C) THE SERVICES GENERALLY, INCLUDING THE MOBILE SERVICES, OR SYSTEMS THAT MAKE THE SERVICES AVAILABLE; OR (D) ANY OTHER INTERACTIONS WITH&nbsp;</span>Powerfun<span>&nbsp;OR ANY OTHER USER OF THE SERVICES, EVEN IF&nbsp;</span>Powerfun<span>&nbsp;OR A&nbsp;</span>Powerfun<span>&nbsp;AUTHORIZED REPRESENTATIVE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</span></p>
                        <p><span>THIS LIMITATION SHALL ALSO APPLY WITH RESPECT TO DAMAGES INCURRED BY REASON OF OTHER SERVICES OR GOODS RECEIVED THROUGH OR ADVERTISED ON&nbsp;</span>Powerfun<span>&nbsp;OR THROUGH THE SERVICES, OR RECEIVED THROUGH ANY LINKS PROVIDED BY&nbsp;</span>Powerfun<span>, THE AFFILIATED WEBSITES, OR THE SERVICES, AS WELL AS BY REASON OF ANY INFORMATION OR ADVICE RECEIVED THROUGH OR ADVERTISED ON&nbsp;</span>Powerfun<span>, THE AFFILIATED WEBSITES, THE SERVICES, OR RECEIVED THROUGH ANY LINKS PROVIDED BY&nbsp;</span>Powerfun<span>, THE AFFILIATE WEBSITES, OR THE SERVICES.</span></p>
                        
                        <p><span>THIS LIMITATION SHALL ALSO APPLY, WITHOUT LIMITATION, TO THE COSTS OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, LOST PROFITS, OR LOST DATA. SUCH LIMITATION SHALL FURTHER APPLY WITH RESPECT TO THE PERFORMANCE OR NON-PERFORMANCE OF&nbsp;</span>Powerfun<span>&nbsp;OR THE SERVICES OR ANY INFORMATION OR MERCHANDISE THAT APPEARS ON, OR IS LINKED OR RELATED IN ANY WAY TO&nbsp;</span>Powerfun<span>, its affiliated websites, OR THE SERVICES. SUCH LIMITATION SHALL APPLY NOTWITHSTANDING ANY FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY AND TO THE FULLEST EXTENT PERMITTED BY LAW.</span></p>
                        
                        <p><span>Without limiting the foregoing, under no circumstances shall&nbsp;</span>Powerfun<span>&nbsp;be held liable for any delay or failure in performance resulting directly or indirectly from acts of nature, forces, or causes beyond its reasonable control, including, without limitation, Internet failures, computer equipment failures, telecommunication equipment failures, other equipment failures, electrical power failures, strikes, labor disputes, riots, insurrections, civil disturbances, shortages of labor or materials, fires, floods, storms, explosions, acts of God, war, governmental actions, orders of domestic or foreign courts or tribunals, non-performance of third parties, or loss of or fluctuations in heat, light, or air conditioning.</span></p>
                        
                        <p><span>IN NO EVENT SHALL&nbsp;</span>Powerfun<span>&nbsp;(OR ITS AFFILIATES, CONTRACTORS, EMPLOYEES, AGENTS, SUPPLIERS, OR THIRD-PARTY PARTNERS INCLUDING, WITHOUT LIMITATION,&nbsp;</span>Powerfun<span>&rsquo;S WIRELESS CARRIER PARTNERS) TOTAL LIABILITY TO A USER FOR ALL DAMAGES, LOSSES, AND CAUSES OF ACTION ARISING OUT OF OR RELATING TO THESE TERMS AND CONDITIONS OF USE, OR A USER&rsquo;S EMPLOYMENT OF THE SERVICES (WHETHER IN CONTRACT, TORT (INCLUDING WITHOUT LIMITATION NEGLIGENCE), WARRANTY, OR OTHERWISE EXCEED THE AMOUNTS PAID BY A USER FOR ACCESSING THE SERVICES DURING THE TWELVE (12) MONTHS IMMEDIATELY PRECEDING THE DATE OF SAID USER&rsquo;S CLAIM OR ONE THOUSAND DOLLARS, WHICHEVER IS GREATER.</span></p>
                        <p><span>A USER FURTHER AGREES THAT THE LIMITATIONS OF LIABILITY SET FORTH ABOVE WILL SURVIVE ANY TERMINATION OR EXPIRATION OF THESE TERMS AND CONDITIONS OF USE, AND WILL APPLY EVEN IF ANY LIMITED REMEDY SPECIFIED HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE.</span></p>
                        
                        <p><span>APPLICABLE LAW MAY NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY OR INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDNIGLY, THE EXCLUSIONS, AND LIMITATIONS SET FORTH ABOVE MAY NOT APPLY IN THEIR ENTIRETY TO A SPECFIC USER. SAID USER, HOWEVER, AGREES THAT&nbsp;</span>Powerfun<span>&rsquo;S LIABILITY WILL BE LIMITED TO THE EXTENT PERMITTED BY LAW.</span></p>
                    </div>
                    <p><span class="title">Modification of the Terms</span></p>
                    <div class="sub-content">
                        <p>Powerfun<span>&nbsp;reserves the right, at its discretion, to change, modify, add, or remove portions of the Terms or any affiliated Guidelines at any time. By a User&rsquo;s consent to the Agreement, he or she obligates himself or herself to periodically check the Agreement and all Guidelines and Policies for any changes. A User&rsquo;s continued use of the Services after the posting of any modifications or changes to the Terms or any affiliated Guidelines or Policies constitutes his or her binding acceptance of such changes. Please note that additional and/or different conditions and terms of use may apply to services or products provided through one or more of&nbsp;</span>Powerfun<span>&rsquo;s partners, advertisers, or business associates, and a User should refer to those before accessing, purchasing, using or subscribing to such services or products. For any material changes to the Terms and Conditions of Use or any Guidelines or Policies, a User acknowledges and agrees that any such amended or modified terms shall automatically be effective thirty (30) days after they are initially posted on the affiliated websites.</span></p>
                    </div>
                    <p><span class="title">OTHER TERMS</span></p>
                    <div class="sub-content">
                        <p><span>The failure of&nbsp;</span>Powerfun<span>&nbsp;to exercise or enforce any right or provision of these Terms will not operate as a waiver of that right or provision. If any provision or part of a provision of these Terms is unlawful, void or unenforceable, that provision or part of the provision is deemed severable from these Terms and does not affect the validity and enforceability of any remaining provisions. The section titles in these Terms are for convenience only and have no legal or contractual effect. Any references to &ldquo;including&rdquo; will be deemed to mean &ldquo;including without limitation.&rdquo; Except as otherwise provided herein, these Terms are intended solely for the benefit of the parties and are not intended to confer third-party beneficiary rights upon any other person or entity.&nbsp;</span>Powerfun<span>&nbsp;will not be liable for any delay or failure of the Platform to the extent caused by circumstances beyond its reasonable control. You agree that communications and transactions between us may be conducted electronically.</span></p>
                        <p><span>We may supply different or additional terms in relation to some features of our Platform, and those different or additional terms become part of your agreement with us if you use those features. If there is a conflict between these Terms and the additional terms, the additional terms will control for that conflict.</span></p>
                    </div>
                    <p><span class="title">Entire Agreement</span></p>
                    <div class="sub-content">
                        <p><span>These Terms (and any related Guidelines of the character detailed above) constitute the entirety of the agreement between&nbsp;</span>Powerfun<span>&nbsp;and Users relative to the subject matter hereof and supersedes and replaces all prior or contemporaneous understandings or agreements, written or oral, regarding such subject matter.</span></p>
                    </div>
                </div>
            </div>
        </div>
        <BottomNavigator />
    </div>
</template>

<script>
import TopNavigator from "@/components/TopNavigator";
import BottomNavigator from "@/components/BottomNavigator";
export default {
    name:'TermSevice',
    components: {
        TopNavigator,
        BottomNavigator,
    },
    mounted(){

    },
    methods: {

    }
}
</script>

<style scoped lang="scss">
.wrap {
    margin-bottom: 20rem;
    >div {
        width:1200rem;
        margin:0 auto;
        background: #252525;
        padding-bottom:21rem ;
        .title {
            color:#fff;
            height: 58rem;
            line-height: 58rem;
            font-size: 42rem;
            text-align: center;
            font-weight: 700;
            padding-top:21rem;
            font-family: "GenSenMaruGothicTW-Regular";
        }
        .content {
            margin-top: 31rem;
            border-top: 1rem solid #444444;
           
            font-family: "GenSenMaruGothicTW-Regular";
            p {
                font-size: 15rem;
                color:#a6a6a6;
                line-height: 1.8;
                padding-left: 25rem;
                padding-right: 25rem;
            }
            ul{
                font-size: 15rem;
                color:#a6a6a6;
                font-weight: 400;
                padding-right: 25rem;
            }
            .title{
                font-size: 32rem;
                color: #fff;
                font-weight: 700;
            }
            .sub-title{
                font-size: 20rem;
                color: #fff;
            }
            .sub-content{
                margin-bottom:38rem;
                line-height:21rem;
            }
            .third-sub-title{
                font-size: 15rem;
                color: #fff;
                font-weight: 400;
            }
        }
    }
}
</style>