import axios from 'axios'
const service = axios.create({
    baseURL: process.env.BASE_API, // api的base_url
    timeout: 20000,// 请求超时时间
    headers: {
        "Accept-Language": "en-US,en;"
    },
    withCredentials: true
})

// service.interceptors.request.use(config=>{
//     config.withCredentials = true;
//     return config;
// });

// respone拦截器
service.interceptors.response.use(
    response => {        
        /**
        * code为非200是抛错 可结合自己业务进行修改
        */
        if (response.data.result) {
            return response.data
        } else {
            return Promise.reject(response.data.errMsg)
        }
    },
    error => {
        console.log('err' + error)// for debug
        return Promise.reject(error)
    }
)
export default service