<template>
  <div>
    <TopNavigator />
    <div v-if="show">
        <div class="head">
            <div style="position:relative; display:inline-block;">
                <img :src="info.WxHeadImg" class="head-img" />
                <img src="~@/assets/男.png" class="sex" v-if="info.Sex == 1" />
                <img src="~@/assets/女.png" class="sex" v-if="info.Sex == 2" />
            </div>
            <div class="nick-name">{{ info.NickName }}</div>
            <div class="summary">
                <div class="panel">
                    <div class="label">Total Riding Time</div>
                    <div class="value">{{ info.TotalTicks }}</div>                    
                    <div class="unit">Hours</div>
                </div>
                <div class="panel">
                    <div class="label">Total Rides</div>
                    <div class="value">{{ info.Count }}</div>
                    <div class="unit">Times</div>
                </div><div class="panel">
                    <div class="label">Total Distance</div>
                    <div class="value">{{ info.TotalDistance }}</div>
                    <div class="unit">KM</div>
                </div>
                <div class="panel">
                    <div class="label">Total Climbed</div>
                    <div class="value">{{ info.TotalClimb }}</div>
                    <div class="unit">M</div>
                </div>
                <div class="panel">
                    <div class="label">Total Calories Burned</div>
                    <div class="value">{{ info.Kcal }}</div>
                    <div class="unit">KCAL</div>
                </div>
            </div>
        </div>
        <div class="main">
                <div class="title">
                    <div class="tabs">
                        <div :class="{ 'selected': tabIndex== 0 }" @click="switchTab(0)">TRAINING DIARY</div>
                        <!-- <div>RACE</div> -->
                        <div :class="{ 'selected': tabIndex == 1 }" @click="switchTab(1)">MY UPLOAD ACTIVITITES</div>
                    </div>
                    <div style="display:flex; align-items:center;">
                        <router-link to="/Mine/Upload"><a href="#/Mine/Upload" class="btn no-select" >Upload an activity</a></router-link>
                        <div class="search">
                            <input type="text" v-model="keyword" @keyup="keyup" placeholder="Search...">
                            <img src="~@/assets/搜索.png" style="width:20rem; height:20rem; cursor:pointer;" @click="search" />
                        </div>
                    </div>
                </div>
                <div class="list" v-if="tabIndex == 0">
                    <div class="item" v-for="item in list" :key="item.Id">
                        <a style="display:flex; flex:1; text-decoration:none;" :href="item.ViewUrl" target="_blank">
                            <img class="cover" :src="item.RouteImage" />
                            <div class="info">
                                <div class="name">{{ item.RouteName }}</div>
                                <div class="time">{{ item.CreateTime | time }}</div>
                                <div style="opacity:0.5; background:#444444; margin-top:9rem; height:1px; color:#444;" />
                                <div class="info1">
                                    <span class="label">Riding time：</span>
                                    <span class="value">{{ item.TrainingTime }}</span>
                                    <span class="label">Distance : </span>
                                    <span class="value">{{ item.TotalDistance.toFixed(2) }}KM</span>
                                    <span class="label">Times : </span>
                                    <span class="value">{{ item.Count }}</span>
                                </div>
                                <div class="info2">
                                    <span class="label">Cycling equipment : </span>
                                    <span class="value">{{ item.CyclingEquipmentName }}</span>
                                </div>
                            </div>
                            <div class="progress">
                                <progress-circle :completed-steps="item.Progress" :total-steps="1" circle-color="#4D4D4D" 
                                    start-color="#f93086" stop-color="#f93086" :diameter=100 :circle-width="4">
                                    <div style="font-size:34rem; color:#fff;">{{ parseInt(item.Progress * 100) }}</div>
                                    <div style="color:#fff;">%</div>
                                </progress-circle>
                            </div>
                            <div class="rank">
                                <div style="display:flex; flex:1; flex-direction:column;">
                                    <div>{{ item.Ranking }}</div>
                                    <div style="font-size:14rem;">Rank</div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div class="empty" v-if="list.length == 0">
                        <img src="~@/assets/found nothing.png"/>
                        <div>Found Nothing</div>
                    </div>
                </div>
                <div class="list myroutes" v-if="tabIndex == 1">
                    <div class="item" v-for="item in myRoutes.list" :key="item.Id">
                        <a style="display:flex; flex:1; text-decoration:none;" :href="item.ViewUrl" target="_blank">
                            <img class="cover" :src="item.CoverImage" />
                            <div class="info">
                                <div class="name">{{ item.Name }}</div>
                                <div class="time">{{ item.CreateTime | time }}</div>
                                <div style="opacity:0.5; background:#444444; margin-top:9rem; height:1px; color:#444;" />
                                <div class="info1">
                                    <span class="label">Distance : </span>
                                    <span class="value">{{ item.Distance.toFixed(2) }}KM</span>
                                    <span class="label">Avg Grade ：</span>
                                    <span class="value">{{ item.AverageGrade.toFixed(2) }}%</span>
                                    <span class="label">Total Climbed ：</span>
                                    <span class="value">{{ item.TotalClimb.toFixed(2) }}M</span>
                                </div>
                                <div class="info2">
                                    <!-- <span class="label">Elevation drop : </span>
                                    <span class="value">{{ item.EleDifference.toFixed(2) }}</span>
                                    <span class="label">Max. elevation : </span>
                                    <span class="value">{{ item.MaxElevation.toFixed(2) }}</span> -->
                                    <span class="label">Difficulty : </span>
                                    <span class="value">{{ item.Hard }}</span>
                                </div>
                            </div>
                            <!-- <div class="progress">
                                <progress-circle :completed-steps="item.Progress" :total-steps="1" circle-color="#4D4D4D" 
                                    start-color="#f93086" stop-color="#f93086" :diameter=100 :circle-width="4">
                                    <div style="font-size:34rem; color:#fff;">{{ parseInt(item.Progress * 100) }}</div>
                                    <div style="color:#fff;">%</div>
                                </progress-circle>
                            </div>
                            <div class="rank">
                                <div style="display:flex; flex:1; flex-direction:column;">
                                    <div>{{ item.Ranking }}</div>
                                    <div style="font-size:14rem;">Rank</div>
                                </div>
                            </div> -->
                        </a>
                    </div>
                    <div class="empty" v-if="myRoutes.list.length == 0">
                        <img src="~@/assets/found nothing.png"/>
                        <div>Found Nothing</div>
                    </div>
                </div>
            </div>
    </div>
    <BottomNavigator />
  </div>
</template>

<script>
import dayjs from 'dayjs';
import request from '@/utils/request';
import TopNavigator from "@/components/TopNavigator";
import BottomNavigator from "@/components/BottomNavigator";
import { ProgressCircle } from 'vue-progress-circle';
import { GetCurrentUser,Logout } from "@/api/userApi";

export default {
    name: "Mine",
    data() {
        return {
            info:{},
            list:[],
            //myRoutes:[],
            pageIndex:-1,
            keyword:'',
            loading:false,
            show:false,
            tabIndex:0,
            myRoutes:{
                list:[],
                pageIndex:-1,
                pageSize:10,
                loading:false
            }
        };
    },
    components: {
        TopNavigator,
        BottomNavigator,
        ProgressCircle
    },
    mounted() {
        let self = this;
        // if(!sessionStorage.getItem("CurrentUser")){
        //     this.$router.push({path:'/Sign?type=1'})
        //     return
        // }
        this.load();
        window.addEventListener('scroll', self.scrollEvent);
    },
    methods: {
        async load(){
            try{
                var user = await GetCurrentUser();
                console.log(user);
                if(user == null){
                    this.$router.push({path:'/Sign?type=1'});
                    return;
                }
            }
            catch(err){
                //console.log(err);
                this.$router.push({path:'/Sign?type=1'});
                return;
            }
            this.loadSummary();
            this.loadList();
            this.loadMyRoutes();
            
        },
        loadSummary(){
            request({
                url:'/User/GetSummary',
                method:'get'
            }).then(res=>{
                this.info = res.data;

                this.show = true;
            });
        },
        loadList(){
            if(this.loading)
                return;
            this.loading = true;
            this.pageIndex ++;
            if(this.pageIndex <= 0){
                this.list = [];
            }
            request({
                url:'/MapRecord/v1/GetList',
                method:'get',
                params:{
                    pageIndex:this.pageIndex,
                    pageSize:20,
                    keyword:this.keyword,
                    type:'routes'
                }
            }).then(res=>{
                this.loading = false;
                console.log(res);

                this.list = this.list.concat(res.data);
            });
        },
        search(){
            if(this.tabIndex == 0){
                this.pageIndex = -1;            
                this.loadList();
            }
            else{
                this.myRoutes.pageIndex = -1;
                this.loadMyRoutes();
            }
        },
        keyup(event){
            if(event.keyCode != 13)
                return;
            //console.log(event);
            this.search();
        },
        scrollEvent() {
            var self = this;
            var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
            var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
            if (scrollTop + windowHeight > scrollHeight - 10) {
                // console.log('test');
                if(self.tabIndex == 0){
                    self.loadList();
                }
                else{
                    self.loadMyRoutes();
                }
            }
        },
        switchTab(index){
            this.tabIndex = index;
        },
        loadMyRoutes(){
            if(this.myRoutes.loading)
                return;
            this.myRoutes.loading = true;
            this.myRoutes.pageIndex ++;
            if(this.myRoutes.pageIndex <= 0){
                this.myRoutes.list = [];
            }
            request({
                url:'/Map/GetMyRoutes',
                method:'get',
                params:{
                    pageIndex:this.myRoutes.pageIndex,
                    pageSize:this.myRoutes.pageSize,
                    keyword: this.keyword
                }
            }).then(res=>{
                this.myRoutes.loading = false;
                this.myRoutes.list = this.myRoutes.list.concat(res.data);
            });
        }
    },
    filters:{
        // time:function(value){
        //     //return dayjs.duration(value, 's').format('HH:mm:ss');
        //     let val = dayjs.duration(value, 's');
        //     return parseInt(val.asHours()) +':' + val.minutes().toString().padStart(2, '0') + ':' + val.seconds().toString().padStart(2,'0');
        // }
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.scrollEvent);
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.head {
    height: 422rem;
    text-align: center;
    background:url('~@/assets/Photo 1-min.png') no-repeat;
    background-size: cover;
    font-family: 'GenSenMaruGothicTW-Bold';
    .head-img {
        width: 140rem;
        height: 140rem;
        margin-top: 39rem;
        border-radius: 50%;
    }
    .sex {
        width: 34rem;
        height: 34rem;
        position: absolute;
        bottom: 3rem;
        right: 5rem;
    }
    .nick-name {
        font-size: 24rem;
        color:#fff;
        margin-top: 4rem;
    }
    .summary {
        margin:30rem auto auto auto;
        width:1200rem;
        display: flex;
        justify-content: space-between;
        .panel {
            // margin-left: 20rem;
            width: 224rem;
            height: 120rem;
            background: #292929;
            background-image: linear-gradient(179deg, rgba(0,0,0,0.05) 0%, rgba(255,255,255,0.05) 100%);
            border-radius: 6px;
            // flex: 1;

            .label {
                font-size: 14rem;
                color: #737373;
                text-align: center;
                font-weight: 700;
                margin-top:20rem;
                // font-family: 'GenSenMaruGothicTW-Bold';
            }
            .value {
                font-size: 34rem;
                color: #FFFFFF;
                text-align: center;
                font-weight: 700;
                height: 47rem;
                line-height: 47rem;
                // font-family: 'GenSenMaruGothicTW-Bold';
                margin-top: 6rem;
            }
            .unit {
                color:#fff;
                font-size: 14rem;
                // font-family: 'GenSenMaruGothicTW-Bold';
            }
        }
    }
}
.main {
    //margin:42rem 300rem 50rem 300rem;
    margin:42rem auto 50rem auto;
    width: 1200rem;
    background: #252525;
    font-family: 'GenSenMaruGothicTW-Bold';
    .btn {
        background: #f93086;
        // width:120rem;
        height:38rem;
        border-radius: 100rem;
        color:#fff;
        font-size: 14rem;
        display: inline-block;
        line-height: 38rem;
        text-decoration:none;
        text-align: center;
        font-weight: 700;
        padding: 0 10rem;
    }

    .title {
        height: 60rem;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #444444;
        .tabs {
            font-size: 16rem;
            color:#737373;
            text-align: center;
            line-height: 60rem;
            font-weight: 700;
        }

        .tabs {
            display: flex;
            &>div{
                // width:100rem;
                margin-left:20rem;
                cursor: pointer;
                &.selected{
                    border-bottom: 2rem solid #F93086;
                    color:#fff;
                }
            }
        }
        .search {
            width:232rem;
            height: 38rem;
            border:2rem solid #4d4d4d;
            border-radius: 100rem;
            padding:10rem;
            box-sizing: border-box;
            margin-left: 20rem;
            position: relative;
            display: flex;
            align-items: center;
            margin-right: 20rem;

            &>img {
                position: absolute;
                right:10rem;
            }
        }
    }
    .list {
        .item {
            padding: 20rem;
            display: flex;
            &:nth-child(even) {
                background: #303030;
            }
            .cover {
                width:205rem;
                height: 120rem;
            }
            .info {
                flex:1;
                margin-left: 20rem;
                .name {
                    font-size:16rem;
                    color:#fff;
                    line-height: 24rem;
                    font-weight: 700;
                }
                .time {
                    font-size: 14rem;
                    color:#737373;
                    line-height: 18rem;
                    font-weight: 500;
                    margin-top: 4rem;
                    font-family: 'GenSenMaruGothicTW-Medium';
                }
                .info1 {
                    margin-top:13rem;                    
                }
                .info1, .info2 {
                    .label {
                        font-size: 14rem;
                        color:#737373;
                        font-weight: 500;
                        font-family: 'GenSenMaruGothicTW-Medium';
                    }
                    .value {
                        font-size: 14rem;
                        color:#a6a6a6;
                        font-weight: 500;
                        margin-right:32rem;
                        font-family: 'GenSenMaruGothicTW-Medium';
                    }
                }
                .info2 {
                    margin-top:3rem;
                }
            }

            .progress {
                width: 100rem;
                margin-left:20rem;
            }
            .rank {
                padding:10rem;
                border-left: 1px solid #44444480;
                margin-left:60rem;
                width:124rem;
                font-size: 34rem;
                color:#737373;
                text-align: center;
                font-weight: 700;
                display: flex;
                align-items: center;
                text-align: center;
            }
        }

        // &.myroutes {
        //     .label {
        //         width:108rem;
        //     }
        // }
    }
}

.empty {
        min-height: 480rem;
        font-size: 24rem;
        color:#4d4d4d;
        font-weight: 700;
        margin-top: 10%;
        text-align: center;
    }
</style>
