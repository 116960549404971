<template>
  <div class="no-scroll">
    <TopNavigator @OnTypeChanged="OnTypeChanged" ref="top"/>
    <div class="line"></div>
    <div class="center" style="flex:1; margin-top:49rem;margin-bottom:50rem;">
      <div class="sign-in-content" v-if="type === 1">
        <div class="left">
          <div class="title">Log In</div>
          <div class="input" style="margin-top:28rem">
            <input class="input-area" type="text"  placeholder="Enter email address" v-model="loginForm.Phone" maxlength="50">
            <!-- v-on:input="OnInput('Phone',$event.target.value)" -->
          </div>
          <div class="input" style="margin-top:15rem">
            <input class="input-area" type="password"  placeholder="Enter password" v-model="loginForm.Pwd" maxlength="50">
          </div>
          <div style="margin-top:15rem;display:flex;width:390rem;">
            <div class="btn-check no-select" style="width:196rem" @click="OnCheck">
                <input type="checkbox" style="margin-right:10rem" v-model="loginForm.RememberMe">
                <div>Remember me</div>
            </div>
            <a class="forget-button" href="#/resetpwd">Forget password?</a>
            <div style="flex:1"></div>
          </div>

          <div class="btn-confirm no-select" style="margin-top:13rem" @click="GoSignIn">Log In</div>
          <div class="btn-reg no-select" style="margin-top:17rem" @click="ResetPassword(2)">New user? Register</div>
          <div class='other-lines'>
            <div class="line"></div>
            <div class="or">or</div>
            <div class="line"></div>
          </div>
          <img class="wx" src="@/assets/登陆-微信.png" @click="GoWx">
        </div>
        <div class="right"></div>
      </div>
      <div v-else-if="type === 2" class="sign-in-content" style="height:570rem">
        <div class="left">
          <div class="title" v-if="from==='wx'">Join PowerFun</div>
          <!-- <div class="title" v-else-if="from==='reset'">Reset Your Password</div> -->
          <div class="title" v-else>Join PowerFun</div>
          <div class="input" style="margin-top:28rem">
            <input class="input-area" type="text" placeholder="Enter email address" v-model="regesterForm.Phone" maxlength="50" />
          </div>
          <div style="margin-top:15rem;display:flex">
            <div class="input" style="width:272rem">
              <input class="input-area" type="text"  placeholder="Verification Code" v-model="regesterForm.Captcha" maxlength="8" />
            </div>
            <div :class="`btn-verify no-select ${btnCaptchaText!='Verify'?'disabled':''}`" @click="GetCaptcha">{{btnCaptchaText}}</div>
          </div>

          <div class="input" :style="`margin-top:15rem;display:${(from !== 'wx' || (from === 'wx' && !isExist))?'flex':'none'}`">
            <!-- v-if="" -->
            <input class="input-area" type="password" placeholder="Enter password" v-model="regesterForm.Pwd" maxlength="50" />
          </div>
          <div class="btn-check no-select" style="margin-top:15rem" @click="OnCheckTerms">
            <input type="checkbox" style="margin-right:10rem" v-model="check">
            <div style="font-size:12rem;">I accept the Terms of Use and Privacy Policy of PowerFun</div>
          </div>
          <div class="btn-confirm no-select" style="margin-top:17rem" @click="GoSignUp">Register</div>
          <div class='other-lines' style="margin-top:30rem">
            <div class="line"></div>
            <div class="or">or</div>
            <div class="line"></div>
          </div>
          <img class="wx" src="@/assets/登陆-微信.png" @click="GoWx">
        </div>
        <div class="right"></div>
      </div>
    </div>
    <BottomNavigator />
  </div>
</template>

<script>
import helper from "@/utils/helper";
import { Login, GetCaptcha, Register, OnWebWxLogin,ResetPassword } from "@/api/userApi";
import TopNavigator from "@/components/TopNavigator";
import BottomNavigator from "@/components/BottomNavigator";
import Vue from "vue";
export default {
  name: "Sign",
  data() {
    return {
        type: 2,
        loginForm: {
            Phone: "",
            Pwd: "",
            RememberMe:false
        },
        regesterForm: {
            Phone: "",
            Pwd: "",
            Captcha: "",
        },
        repeatPassword:'',
        btnCaptchaText: "Verify",
        check: false,
        lock: false,
        sec: 60,
        from: null,
        //是否存在，用于微信注册页面关闭密码框
        isExist:false
    };
  },
  components: {
        TopNavigator,
        BottomNavigator,
  },
  mounted() {
        console.log(BottomNavigator)
        if (!this.$route.query || [1, 2, 3].indexOf(Number(this.$route.query.type)) === -1) {
        this.$router.replace("404");
        }
        if (this.$route.query && this.$route.query.from) {
        this.from = this.$route.query.from;
        }
  },
  methods: {
    ResetPassword(val,from){
      let path = `#/Sign?type=${val}`
      if(from){
        path+= `&from=${from}`
      }
      window.location.href = path
      window.location.reload()
      // console.log(this.$router)
      // this.type = val;
      // if(this.$refs.top){
      //   this.$refs.top.type = val
      // }
      // this.from = "reset";
    },
    OnTypeChanged(val) {
      this.type = val;
      if(this.$refs.top){
        this.$refs.top.type = val
      }
    },
    // OnInput(key, value) {
    //   this.$set(this.loginForm, key, value);
    // },
    GoSignIn() {
      Login(this.loginForm)
        .then((res) => {
          sessionStorage.setItem("CurrentUser", JSON.stringify(res.data));
          this.$router.push("/Mine/Index");
        })
        .catch((ex) => {
          this.$toasted.show(ex, {
            type: "error",
          });
        });
    },
    GoSignUp() {
      const resFunc = (res) => {
          this.$toasted.show("Register Success", {
            type: "success",
          });
          sessionStorage.setItem("CurrentUser", JSON.stringify(res.data));
          this.$router.push("/Mine/Index");
        },
        exFunc = (ex) => {
          this.$toasted.show(ex, {
            type: "error",
          });
        };
        if(this.check == false){
            this.$toasted.show('You must accept the Terms and Conditions.',{ type:'error' });
            return;
        }

        if (this.from === null) {
            
            Register(this.regesterForm).then(resFunc).catch(exFunc);
        } else if (this.from === "wx") {
            OnWebWxLogin(
            Object.assign(
                {
                UnionId: this.$route.query.unionId,
                openId: this.$route.query.openId,
                },
                this.regesterForm
            )
            ).then(resFunc).catch(exFunc);
        }
    },
    OnCheck() {
      this.loginForm.RememberMe = !this.loginForm.RememberMe;
    },
    OnCheckTerms(){
      this.check = !this.check
    },
    GetCaptcha() {
      if (this.btnCaptchaText !== "Verify") {
        return;
      }
      if (this.lock) {
        this.$toasted.show("Please operate later", {
          type: "error",
        });
        return;
      }
      this.lock = true;
      GetCaptcha(this.regesterForm).then((res) => {
          this.$toasted.show('A verification code has been send to your email address.');
          this.sec = 60;
          this.btnCaptchaText = `Again(${this.sec--})`;
          const timer = setInterval(() => {
            this.btnCaptchaText = `Again(${this.sec--})`;
            if (this.sec <= 0) {
              clearInterval(timer);
              this.btnCaptchaText = "Verify";
            }
          }, 1000);
          this.isExist = res.data.isExist
          this.lock = false;
        })
        .catch((ex) => {
          this.lock = false;
          this.$toasted.show(ex, {
            type: "error",
          });
        });
    },
    GoWx() {
      window.location.href = `https://open.weixin.qq.com/connect/qrconnect?appid=wx9e8ca734e024f084&redirect_uri=https%3A%2F%2Fwx.powerfun.com.cn%2FNoAuth%2Fv1%2FWxWebLogin&response_type=code&scope=snsapi_login&state=web#wechat_redirect`;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang='scss'>
.forget-button{
  margin: 5rem auto;
  width: 241rem;
  text-align: right;
  color:#f93086;
  font-size: 15rem;
  font-family: 'GenSenMaruGothicTW-Bold';
  cursor: pointer;
  
}
.forget-button:hover{
    color: #fff;
}
.line {
  width: 100%;
  height: 1rem;
  background: #3d3d3f;
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.sign-in-content {
  width: 1200rem;
  height: 570rem;
  display: flex;
  border-radius: 2rem;
  background: #252525;
}
.sign-in-content .left {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.sign-in-content .left .title {
  margin-top: 47rem;
  font-family: "GenSenMaruGothicTW-Bold";
  font-size: 28rem;
  line-height: 45rem;
  font-weight: 700;
  color: #fff;
}
.sign-in-content .right {
  width: 50%;
  height: 100%;
  border-radius: 0 2rem 2rem 0;
  background: url("../../assets/注册登陆-背景1.png");
  background-size: cover;
}
.btn-verify {
  margin-left: 10rem;
  width: 108rem;
  height: 45rem;
  background: #f93086;
  border-radius: 22.5rem;
  font-family: "GenSenMaruGothicTW-Bold";
  font-size: 16rem;
  color: #ffffff;
  letter-spacing: 0;
  line-height: 30rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.other-lines {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 390rem;
  height: 30rem;
  margin-top: 32rem;
  .line {
    width: 175rem;
    height: 2rem;
    background-color: #444444;
  }
  .or {
    font-family: "GenSenMaruGothicTW-Bold";
    font-size: 20rem;
    color: #737373;
    letter-spacing: 0;
    text-align: center;
    line-height: 30rem;
    text-shadow: 0 2rem 4rem rgba(0, 0, 0, 0.5);
    font-weight: 700;
  }
}
.wx {
  width: 46rem;
  height: 46rem;
  margin-top: 15rem;
  cursor: pointer;
}
</style>
