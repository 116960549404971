import request from '@/utils/request'
export function Login(params) {
  return request({
    url:'/NoAuth/v1/Login',
    method:'post',
    params,
    // withCredentials:true
  })
}
export function GetCaptcha(params) {
  return request({
    url:'/NoAuth/v1/GetCaptcha',
    method:'post',
    params,
  })
}
export function Register(params) {
  return request({
    url:'/NoAuth/v1/Register',
    method:'post',
    params,
  })
}
export function OnWebWxLogin(params) {
  return request({
    url:'/NoAuth/v1/OnWebWxLogin',
    method:'post',
    params,
  })
}
let userSession = null;
export function GetCurrentUser(params) {
    if(userSession != null){
        return Promise.resolve(userSession);
    }
    return new Promise((resolve, reject)=>{
        request({
            url:'/User/GetCurrentUser',
            method:'get',
        }).then((res)=>{
            userSession = res;
            resolve(res);
        }).catch(ex=>{
            reject(ex);
        })
    });
}

export function Logout() {
    return new Promise((resolve, reject)=>{
            request({
            url:'/User/Logout',
            method:'post',
        }).then(res=>{
            userSession = null;
            window.sessionStorage.removeItem('CurrentUser');
            resolve(res);
        }).catch(ex=>{
            reject(ex);
        })
    });
}

export function ResetPassword(params) {
  return request({
    url:'/NoAuth/v1/ResetPassword',
    method:'post',
    params
  })
}