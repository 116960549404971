<template>
  <div class="top-content">
      <div style="width:1200rem; margin:0 auto; display:flex; align-items:center; height:100%;">
        <img src="@/assets/White.png" class="logo" @click="GoPage('Index')">
        <div style="display:flex;height:100%;" v-if="routeName !== 'Sign'">
        <router-link v-for="item in routeList" :key="item.name" class="center-route no-select" :to="item.path">
            <div style="height:4rem"></div>
            <div :class=" `${item.current?'selected-tab-name':'tab-name'}`">{{item.display}}</div>
            <div v-if="item.current" class="bottom-line" />
            <div v-else style="height:4rem"></div>
        </router-link>
        </div>
        <div style="flex:1"></div>
        <div class="right" v-if="currentUser===null">
        <div :class="`button no-select ${type===2?'empty':''}`" @click="GoSign(1)">Log In</div>
        <div :class="`button no-select ${type===1?'empty':''}`" @click="GoSign(2)">Register</div>
        </div>
        <div class="right-user" v-else>
        <router-link class="my-center no-select" to="/Mine/Index">My Center</router-link>
        <!-- <div class="line"></div>
        <div class="nickname">{{currentUser.NickName}}</div>
        <img :src="currentUser.WxHeadImg" class="avatar"> -->
        <div class="tools" @click.stop="OpenTools"></div>
        <div class="tools-dialog" v-if="showToolsDialog">
            <!-- <router-link class="tools-content no-select" to="/Mine/Upload">DIY Routes</router-link> -->
            <div class="tools-content no-select" @click="Logout">Log Out</div>
        </div>
        </div>
    </div>
  </div>
</template>

<script>
import helper from "@/utils/helper";
import { GetCurrentUser,Logout } from "@/api/userApi";
export default {
  name: "TopNavigator",
  data() {
    return {
      routeList: helper.routeList,
      type: 2, //2 sign up 平时+注册 1 sign in 登录
      routeName: "",
      currentUser: null,
      showToolsDialog: false,
      fun:()=>{
        this.showToolsDialog = false
      }
    };
  },
  beforeMount() {
    this.routeName = this.$route.name;
    this.routeList.map((x) => {
      if (this.$route.name && this.$route.name.indexOf(x.name) == 0) {
        x.current = true;
      } else {
        x.current = false;
      }
    });
    // if (centerRoute) {
    //   centerRoute.current = true;
    // }
  },
  created() {
    // localStorage.setItem("123",'456')
    const signParams = this.$route.query;
    if (this.$route.name === "Sign" && signParams) {
      this.type = Number(signParams.type);
    }
    const userJson = sessionStorage.getItem("CurrentUser")
    if(userJson){
      this.currentUser = JSON.parse(userJson);
    }
    GetCurrentUser().then((res) => {
      if (res.result) {
        sessionStorage.setItem("CurrentUser", JSON.stringify(res.data.user));
        this.currentUser = res.data.user;
      }
    }).catch(ex=>{
      this.currentUser = null;
      sessionStorage.removeItem("CurrentUser");
      if(this.$route.path === '/Mine/Index'){
        this.$router.push({path:'/Sign?type=1'})
      }
    });
  },
  beforeCreate() {},
  mounted() {
    this.$EventBus.$on('ClickOtherInToolDialog',this.fun)
  },
  beforeDestroy(){
    this.$EventBus.$off('ClickOtherInToolDialog',this.fun)
  },
  watch: {
    "$route.path": function (newVal, oldVal) {
      console.log(53, `new_path = ${newVal}, old_path = ${oldVal}`);
    },
    type: function (newVal, oldVal) {
      this.$emit("OnTypeChanged", newVal);
    },
  },
  methods: {
    GoPage(name) {
      helper.GoPage(this, name);
    },
    GoSign(type) {
      let path = `#/Sign?type=${type}`
      window.location.href = path
      window.location.reload()
      // if (this.$route.name === "Sign") {
      //   if (type != this.type) {
      //     this.type = type;
      //   } else {
      //     return;
      //   }
      // }
      // this.$router.replace({ name: `Sign`, query: { type } });
    },
    OpenTools($event) {
      console.log($event);
      this.showToolsDialog = !this.showToolsDialog;
    },
    DIYRoutes() {
      this.$router.replace({ path: "/Mine/Upload" });
    },
    Logout() {
      Logout().then(res=>{
        this.currentUser = null;
        this.$router.push({ path: "/Sign?type=1" });
      }).catch(ex=>{
        this.$toasted.show(ex,{error:'error'})
      })
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.top-content {
  width: 100%;
  height: 83rem;
  display: flex;
  align-items: center;
}
.logo {
  width: 200rem;
  height: 33rem;
  margin-right: 57.33rem;
  /* margin-left: 361.42rem; */
  cursor: pointer;
}

.top-content .center-route {
  height: 100%;
  color: #acacac;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  margin-right: 47.25rem;
  font-size: 16rem;
  min-width: 51rem;
  cursor: pointer;
  font-family: "GenSenMaruGothicTW-Bold";
}
.top-content .center-route .bottom-line {
  width: 51rem;
  height: 4rem;
  background-color: #f93086;
}
.top-content .center-route .tab-name {
  color: #acacac;
}
.top-content .center-route .tab-name:hover {
  color: #fff;
}
.top-content .center-route .selected-tab-name {
  color: #F93086;
}

.top-content .right {
  /* margin-right: 389rem; */
  display: flex;
}
.top-content .right .button {
  height: 38rem;
  width: 100rem;
  border-radius: 19rem;
  font-size: 14rem;
  line-height: 14rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #f93086;
  font-family: "GenSenMaruGothicTW-Bold";
  cursor: pointer;
}
.top-content .right .empty {
  background-color: #f9308600;
}
.top-content .right-user {
  /* margin-right: 348rem; */
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
  cursor: pointer;

    .my-center {
        //   width: 100rem;
        height: 20rem;
        line-height: 20rem;
        font-family: "GenSenMaruGothicTW-Bold";
        font-size: 15rem;
        color: #ffffff;
        text-align: right;
        font-weight: 700;
    }

    .line {
        width: 2rem;
        height: 22rem;
        opacity: 0.3;
        background: #848e92;
        margin-right: 18rem;
    }
}


.top-content .right-user .nickname {
  height: 20rem;
  font-family: GenSenMaruGothicTW-Bold;
  font-size: 15rem;
  color: #ffffff;
  text-align: left;
  font-weight: 700;
  margin-right: 15rem;
  max-width: 100rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.top-content .right-user .avatar {
  width: 40rem;
  height: 40rem;
  border-radius: 20rem;
}
.top-content .right-user .tools {
  width: 50rem;
  height: 50rem;
  background: url("../assets/Menu White.png") center;
  background-size: cover;
  cursor: pointer;
}
.top-content .right-user .tools-dialog {
  width: 116rem;
//   height: 79rem;
  position: absolute;
  box-sizing: border-box;
  padding: 18rem;
  top: 62rem;
  right: 14rem;
  background: url("../assets/base.png");
  background-size: cover;
}
.top-content .right-user .tools-dialog .tools-content {
  font-size: 12rem;
  color: #eeeeee;
  height: 50%;
  display: flex;
  align-items: center;
  font-weight: 700;
  cursor: pointer;
}
</style>
