import Vue from 'vue'
import App from './App.vue'
import router from './route'
import Toasted from 'vue-toasted';
Vue.use(Toasted,{duration:2000,position:'top-center',containerClass:'pf-toast'})
import dayjs from 'dayjs';

import './styles/index.less'

var duration = require('dayjs/plugin/duration')
dayjs.extend(duration);
Vue.filter('date', function (value) {
    if (value) {
      return dayjs(value).format('MM/DD/YYYY')
    }
  })
  
  Vue.filter('time', function (value) {
      if (value) {
          console.log(value);
          return dayjs(value).format('MM/DD/YYYY HH:mm:ss')
      }
  })
  Vue.filter('time1', function (value) {
      if (value) {
          return dayjs(value).format('HH:mm')
      }
  })
  
  Vue.filter('short-time', function (value) {
      if (value) {
          return dayjs(value).format('MM-DD HH:mm')
      }
  })
  
  Vue.filter('short-time1', function (value) {
      if (value) {
        return dayjs(value).format('YYYY-MM-DD HH:mm')
      }
  })

Vue.config.productionTip = false
Vue.prototype.$EventBus = new Vue();
new Vue({
  router,
  render:h=>h(App)
}).$mount('#app')
