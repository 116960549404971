<template>
  <div class="border-box">
    <TopNavigator />
    <div class="index-top-content">
        <div style="margin:0 auto; width:1200rem;">
            <div class="title">TRAIN WITHOUT LEAVING HOME<br />THINKRIDE AROUND THE WORLD</div>
            <div class="buttons">
                <div class="btn btn1 no-select" @click="Download">DOWNLOAD</div>
                <!-- <div class="btn btn2" @click="LearnMore">Learn More</div> -->
            </div>
        </div>
    </div>
    <div class="index-bottom-content">
      <!-- <div class="video">
        <div class="title">To guide the video</div>
        <div class="sub-title">Added textAdded textAdded textAdded textAdded textAdded textAdded textAdded textAdded text</div>
        <video class="video-content" controls @click="Play" src="https://www.runoob.com/try/demo_source/mov_bbb.mp4"></video>
        <div class="video-desc">
          <div class="title1">Video title</div>
          <div class="sub-title1">Video introductionVideo introductionVideo introductionVideo introductionVideo introductionVideo introductionVideo introduction</div>
        </div>
      </div>
      <div class="why">
        <div class="title">Why powerfun?</div>
        <div class="sub-title">Provide professional training, focus on serving every traveler, continue to provide better service to </div>
      </div>
      <div class="benefit">
        <div :class="`content ${item.active?'content-active':'content-noactive'}`" v-for="item in benefits" :key="item.name" @mouseover="OnMouseOver(item.name)" @mouseleave="OnMouseLeave()">
          <img :src="item.active?item.url_active:item.url" class="icon">
          <div class="text">{{item.name}}</div>
        </div>

      </div> -->
      <div class="routestop">
        <div class="title">ROUTES</div>
        <div class="subtitle">Enjoy the most authentic route experience thanks to race tracks arond the world and accept challenge from players all over the world.
If you can't find the route you like here, you can also add your favorite line.
Feel the adrenaline and experience the most complete racing ever with PowerFun!</div>
        <img src="@/assets/routes.png" class="img" style="margin-top:40rem;repeat:no-repeat;">
      </div>
      <div class="multiple-mode">
        <div class="title">MULTIPLE MODE SELECTION</div>
        <div class="sub-title">PowerFun is a cycling platform where you can race and train with riders from all over the world.
We have three modes for you to choose, Routes, Race and Training. Go and get what you want here.</div>
      </div>
    </div>
    <div class="index-bottom-content">
      <div class="multiple-mode-custom">
        <div class="bg-ab" />
        <img src="@/assets/首页-背景4.png" class="pc">
      </div>
    </div>
    <div class="index-bottom-content" style="margin-top:-115rem;">
      <div class="multiple-mode">
        <div class="routes">
          <img src="@/assets/首页-背景4-图1.png">
          <div class="text">
            <div class="title">ROUTES</div>
            <div class="sub-title">PowerFun will be a journey around the globe thanks to the largest number of race tracks in the world. Jump into an amazing and dynamic adventure that interacts with your decisions and hurtle down breathtaking roads all around the world.</div>
          </div>
        </div>
        <div class="routes" style="margin-top:50rem">
          <div class="text">
            <div class="title">RACE</div>
            <div class="sub-title">If riding alone is boring, enjoy a comfortable online multiplayer game experience through our dedicated server.</div>
          </div>
          <img src="@/assets/首页-背景4-图2.png">
        </div>
        <div class="routes" style="margin-top:50rem;margin-bottom:84rem;">
          <img src="@/assets/首页-背景4-图3.png">
          <div class="text">
            <div class="title">TRAINING</div>
            <div class="sub-title">The most professional courses are offered here, where both experts and beginners can train themselves to achieve their goals.</div>
          </div>
        </div>
      </div>
    </div>
    <BottomNavigator />
  </div>
</template>

<script>
import helper from "@/utils/helper";
import TopNavigator from "@/components/TopNavigator";
import BottomNavigator from "@/components/BottomNavigator";
export default {
  name: "Index",
  data() {
    return {
      benefits: [
        {
          url: require("@/assets/Improve mental health.png"),
          url_active: require("@/assets/Improve mental health 备份.png"),
          name: "Improve mental health",
          active: false,
        },
        {
          url: require("@/assets/promote fat loss.png"),
          url_active: require("@/assets/promote fat loss备份.png"),
          name: " promote fat loss",
          active: false,
        },
        {
          url: require("@/assets/build muscle.png"),
          url_active: require("@/assets/build muscle备份.png"),
          name: "build muscle",
          active: false,
        },
        {
          url: require("@/assets/develop your own social circle.png"),
          url_active: require("@/assets/develop your own social circle备份.png"),
          name: "develop your own social circle",
          active: false,
        },
        {
          url: require("@/assets/weather free.png"),
          url_active: require("@/assets/weather free备份.png"),
          name: "weather free",
          active: false,
        },
        {
          url: require("@/assets/power training.png"),
          url_active: require("@/assets/power training备份.png"),
          name: "power training",
          active: false,
        },
        {
          url: require("@/assets/non-interference cycling.png"),
          url_active: require("@/assets/non-interference cycling备份.png"),
          name: "non-interference cycling",
          active: false,
        },
        {
          url: require("@/assets/training continuity.png"),
          url_active: require("@/assets/training continuity备份.png"),
          name: "training continuity",
          active: false,
        },
        {
          url: require("@/assets/fun.png"),
          url_active: require("@/assets/fun备份.png"),
          name: "fun",
          active: false,
        },
        {
          url: require("@/assets/cycling all the time.png"),
          url_active: require("@/assets/cycling all the time备份.png"),
          name: "cycling all the time",
          active: false,
        },
      ],
    };
  },
  components: {
    TopNavigator,
    BottomNavigator,
  },
  beforeMount() {},
  methods: {
    test() {
      console.log(helper);
      // helper.width = width
    },
    Play() {
      console.log(19);
    },
    OnMouseLeave() {
      this.benefits.map((v) => (v.active = false));
    },
    OnMouseOver(name) {
      // onmouseleave()
      this.benefits.find((x) => x.name === name).active = true;
    },
    Download() {
      helper.GoPage(this, "Download");
    },
    LearnMore() {
      helper.GoPage(this, "About");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang='scss'>
.routestop {
  margin-top: 61rem;
  font-family: "GenSenMaruGothicTW-Medium";
  display: flex;
  flex-direction: column;
  align-items: center;
  width:1200rem;
  .title {
    width: 167rem;
    height: 78rem;
    font-size: 52rem;
    color: #ffffff;
    text-align: center;
    line-height: 78rem;
    font-weight: 700;
  }
  .subtitle {
    margin-top: 3rem;
    // width: 920rem;
    // height: 116rem;
    font-size: 22rem;
    color: #a6a6a6;
    text-align: left;
    line-height: 30rem;
    font-weight: 700;
  }
  .img {
    width: 1200rem;
    height: 779rem;
  }
}
</style>
<style scoped>
.index-top-content {
  width: 100%;
  height: 881rem;
  background: url("../../assets/首页-背景1.png");
  background-size: cover;
  padding: 195.32rem 0rem 333rem 0rem;
  box-sizing: border-box;
}
.index-top-content .title {
  /* width: 913rem; */
  font-size: 64rem;
  height: 156rem;
  font-family: "GenSenMaruGothicTW-Bold";
  color: #fff;
  font-weight: 700;
  line-height: 78rem;
}
.index-top-content .buttons {
  display: flex;
  margin-top: 32.59rem;
}
.index-top-content .buttons .btn {
  width: 196rem;
  height: 60rem;
  border-radius: 31rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20rem;
  font-family: "GenSenMaruGothicTW-Bold";
  font-weight: 700;
  cursor: pointer;
}
.index-top-content .buttons .btn1 {
  background-color: #f93086;
  color: #fff;
}
.index-top-content .buttons .btn2 {
  border: 2rem solid #a6a6a6;
  color: #a6a6a6;
  margin-left: 20rem;
}

.index-bottom-content {
  width: 100%;
  /* height: 4956rem; */
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.index-bottom-content .video {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 96rem;
  font-family: "GenSenMaruGothicTW-Bold";
}
.index-bottom-content .video .title {
  width: 472rem;
  height: 78rem;
  font-size: 52rem;
  color: #ffffff;
  text-align: center;
  line-height: 78rem;
  font-weight: 700;
}
.index-bottom-content .video .sub-title {
  width: 920rem;
  height: 116rem;

  font-size: 22rem;
  color: #737373;
  text-align: left;
  line-height: 30rem;
  font-weight: 700;
  margin-top: 3rem;
}
.index-bottom-content .video .video-content {
  margin-top: 15rem;
  width: 1200rem;
  height: 570rem;
  /* background-color: red; */
  border-radius: 6rem 6rem 0 0;
}
.index-bottom-content .video .video-desc {
  width: 1200rem;
  background-color: #252525;
  border-radius: 0 0 6rem 6rem;
  font-family: "GenSenMaruGothicTW-Bold";
  padding: 53rem 44rem;
}
.index-bottom-content .video .video-desc .title1 {
  width: 100%;
  height: 30rem;
  font-size: 27rem;
  color: #bfbfbf;
  letter-spacing: 0;
  line-height: 30rem;
  font-weight: 700;
}
.index-bottom-content .video .video-desc .sub-title1 {
  margin-top: 18rem;
  width: 100%;
  font-size: 18rem;
  color: #737373;
  line-height: 25rem;
  font-weight: 700;
}
.index-bottom-content .why {
  width: 100%;
  height: 633rem;
  background: url("../../assets/首页-背景2.png");
  background-size: cover;
  margin-top: 110rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 199rem;
  padding-bottom: 237rem;
  box-sizing: border-box;
}
.index-bottom-content .why .title {
  width: 399rem;
  height: 78rem;
  font-family: "GenSenMaruGothicTW-Bold";
  font-size: 52rem;
  color: #ffffff;
  text-align: center;
  line-height: 78rem;
  font-weight: 700;
}
.index-bottom-content .why .sub-title {
  margin-top: 3rem;
  width: 920rem;
  height: 116rem;
  font-family: "GenSenMaruGothicTW-Bold";
  font-size: 22rem;
  color: #737373;
  text-align: center;
  line-height: 30rem;
  font-weight: 700;
}

.index-bottom-content .benefit {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  justify-content: space-between;
  width: 1200rem;
  height: 450.75em;
  margin-top: 79rem;
}
.index-bottom-content .benefit .content {
  width: 201rem;
  height: 201rem;
  border-radius: 6rem;
  padding-top: 54rem;
  padding-bottom: 33rem;
  font-family: "GenSenMaruGothicTW-Bold";
  font-size: 18rem;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.index-bottom-content .benefit .content-active {
  background-color: #f93086;
  color: #ffffff;
}
.index-bottom-content .benefit .content-noactive {
  background-color: #252525;
  color: #a6a6a6;
}
.index-bottom-content .benefit .content .icon {
  /* width: 49rem;
  height: 44rem; */
  margin-bottom: 16rem;
}
.index-bottom-content .benefit .content .text {
  width: 178rem;
  height: 50rem;
  text-align: center;
}
.index-bottom-content .multiple-mode {
  margin-top: 88rem;
  font-family: "GenSenMaruGothicTW-Bold";
  display: flex;
  flex-direction: column;
  align-items: center;
  width:1200rem;
}

.multiple-mode-custom {
  margin-top: 40rem;
  font-family: "GenSenMaruGothicTW-Bold";
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}
.index-bottom-content .multiple-mode-custom .bg-ab {
  width: 100%;
  height: 852rem;
  margin-top: 12rem;
  background: url("../../assets/首页-背景3.png");
  background-size: cover;
  z-index: -100;
}
.index-bottom-content .multiple-mode-custom .pc {
  margin-top: -799rem;
  z-index: 200;
  width: 813rem;
  height: 696rem;
}
.index-bottom-content .multiple-mode .title {
  /* width: 615rem; */
  height: 78rem;
  font-size: 52rem;
  line-height: 78rem;
  font-weight: 700;
  text-align: center;
  color: #fff;
}
.index-bottom-content .multiple-mode .sub-title {
  margin-top: 3rem;
  /* width: 885rem; */
  /* height: 116rem; */
  font-size: 22rem;
  color: #737373;
  text-align: left;
  line-height: 30rem;
  font-weight: 700;
}
.index-bottom-content .multiple-mode .routes {
  margin-top: 60rem;
  z-index: 200;
  width: 1200rem;
  height: 438rem;
  background-color: #252525;
  box-shadow: 0 2rem 5rem 0 rgba(0, 0, 0, 0.1);
  display: flex;
  border-radius: 6rem;
  overflow: hidden;
}
.index-bottom-content .multiple-mode .routes img {
  width: 780rem;
  height: 438rem;
}
.index-bottom-content .multiple-mode .routes .text {
  flex: 1;
  padding: 59rem 40rem;
}
.index-bottom-content .multiple-mode .routes .text .title {
  width: 227rem;
  height: 30rem;
  font-size: 27rem;
  color: #d9d9d9;
  line-height: 30rem;
  font-weight: 700;
  text-align: left;
}
.index-bottom-content .multiple-mode .routes .text .sub-title {
  margin-top: 20rem;
  width: 340rem;
  font-family: 'GenSenMaruGothicTW-Medium';
  font-size: 18rem;
  color: #4d4d4d;
  letter-spacing: 0;
  line-height: 25rem;
  font-weight: 700;
  text-align: left;
}
.sub-title,
.sub-title1 {
  color: #a6a6a6 !important;
}
.title,
.title1 {
  color: #fff !important;
}
</style>
