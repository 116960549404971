<template>
  <div>
    <TopNavigator />

    <div id="container" class="container" >
      <div id="head" class="head">
         <router-link to="/Mine/Index"><img class="back" src="@/assets/返回.png"></router-link>
      </div>
      <div id="body" class="body">

        <!-- function button -->
        <div id="body-top" class="body-top">
          <div class="title">Upload and Sync You Activities</div>
          <div class="button-group">
            <!-- <router-link to="/Mine/Index"> <button class="button"> Cancel </button></router-link> -->
            <button v-if="!loading" :disabled="!showSave" :class="!showSave? 'disabled-button':'primary-button'" @click="upload">Save</button>
            <Circle2 v-if="loading" ></Circle2>
          </div>
        </div>

        <div class="line"></div>

        <!-- fit upload -->
       
        <div class="label">Upload Exercise Data</div>
 
        <input type="file" name="file" id="file_headerpic"  @change="preUploadFile"/>
        <label for="file_headerpic"><img class="upload-icon" src="@/assets/上传.png">{{fileName}} <span style="margin-left:155rem;font-family:'GenSenMaruGothicTW-Regular';color:#a6a6a6" >Works for .fit file 20M or smaller, Not allowed duplicate uploads. The process takes about 2-3minutes.</span></label>
        <div v-if="showSave" class="remark" style="margin-top:15rem;display:flex;justify-content: space-between;">
          <div>Date:{{startTime}} </div>
          <div>Riding time: {{totalElapsedTime}}  </div>
          <div>Mileage:{{totalDistance}}KM  </div>
        </div>

        <div class="line"></div>
        <!-- basic information -->
        <div style="display:flex;">
          <div style="display:flex;flex-direction:column;">
            <div class="label">Type of Activity</div>
            <input class="min-text" type="text" :disabled="true" style="margin-left:30rem;" v-model="sportType" placeholder="Cycle"/>
          </div>
          <div style="display:flex;flex-direction:column;">
            <div class="label" style="margin-left:20rem;">Cycling Type</div>
            <input class="min-text" type="text" :disabled="true" style="margin-left:20rem;" v-model="subSportType" placeholder="Cycle"/>
           </div>

          <!-- <div style="display:flex;flex-direction:column;">
            <div class="label" style="margin-left:20rem;" >Mark the type of ride</div>
            <Dropdown style="width:270rem;height:45rem;margin-left:20rem;"
              :options="rideTypeOptions" 
              :selected="rideType" 
              v-on:updateOption="methodToRunOnSelect" 
              :placeholder="'Select an Item'"
              :closeOnOutsideClick="true"
              >
            </Dropdown>
          </div> -->
          <!-- <div style="display:flex;flex-direction:column;">
            <div class="label" style="margin-left:20rem;">Bicycle <span class="label" style="color:#F93086;padding-left:135rem; cursor: pointer;" >+Add</span> </div>
            <Dropdown style="width:270rem;height:45rem;margin-left:20rem;"
              :options="bicycleOptions" 
              :selected="bicycle" 
              v-on:updateOption="methodToRunOnSelect" 
              :placeholder="'Select an Item'"
              :closeOnOutsideClick="true">
            </Dropdown>
          </div> -->
        </div>

        <div class="label">Title</div>
        <input class="text" type="text" v-model="title" placeholder="Please fill in"/>

        <div class="label">Explain</div>
        <textarea class="rich-text" autocomplete="false" v-model="remark" placeholder="Please fill in"/>
      </div>
       
    </div>
    
    <BottomNavigator />
  </div>
</template>

<script>
import TopNavigator from "@/components/TopNavigator";
import BottomNavigator from "@/components/BottomNavigator";
import request from '@/utils/request';
import {Circle2} from 'vue-loading-spinner'
//import Dropdown from "@/components/vue-dropdown/dropdown";

export default {
  name: "Upload",
  data() {
    return {
      rideTypeOptions: [{"name":"outdoor"},{"name":"Indoor"}],
      rideType: {
        name: 'Please select ride type',
      },
      bicycleOptions: [{"name":"bicycle1"},{"name":"bicycle2"}],
      bicycle: {
        name: 'Please select a bicycle',
      },
      options:[{name:1},{name:2}],
      fitfile:null,
      title:'',
      remark:'',
      totalElapsedTime:'',
      totalDistance:0,
      startTime:'',
      subSportType:'',
      sportType:'',
      showSave:false,
      loading:false,
      showFileCloseBtn:false,
      fileName:'Select Fit File'
    };
  },
  components: {
    TopNavigator,
    BottomNavigator,
    Circle2,
    //Dropdown
  },
  mounted() {

  },
  methods: {
     methodToRunOnSelect(payload) {
      this.object = payload;
     },
     //解析fit文件
     preUploadFile(event){
      var e=window.event||event;
      // 获取当前选中的文件
      var file = e.target.files[0];
      let self = this;
      if(file == null)
        return;
      //文件格式校验
      if(file.name.indexOf('.fit') === -1)
      {
          self.$toasted.show("incorrect fit file!",{
              type:"error"
          })
          return;
      }
      //文件大小校验
      let fitSize = file.size/ (1024 * 1024);
      if(fitSize > 20)
      {
          self.$toasted.show("file size over 20M!",{
              type:"error"
          })
          return;
      }

      self.fitfile = file;
      self.fileName = self.fitfile.name;

      var formdata = new FormData();
      formdata.append('file',self.fitfile);

      self.loading = true;
      request({
          url:'/CycleComputer/PreImport',
          method:'post',
          data:formdata,
          headers:{
            'Content-Type':'multipart/form-data'
          }
          }).then(res=>{
            self.loading = false;
            if(res.result)
            {
               let data = res.data;
               self.totalElapsedTime = data.TotalElapsedTime;
               self.startTime = data.StartTime;
               self.subSportType = data.SubSportType;
               self.sportType = data.SportType;
               self.totalDistance = data.TotalDistance;
               self.showSave = true;
            }
            else
            {
               self.$toasted.show(res.errMsg,{
                  type:"error"
               });

            }
        }).catch(ex=>{
           self.$toasted.show(ex,{type:"error" });
           this.loading = false;
           self.fitfile = null;
           self.fileName = 'Select Fit File';
        });
     },
     //上传fit文件
     upload()
     {
       let self = this;
       let file = self.fitfile
        //校验Title必填
        if(self.title == '')
        {
          this.$toasted.show("required title!",{type:"error"});
          return;
        }
        if(file == null)
        {
          this.$toasted.show("required  fit file!",{type:"error"});
          return;
        }
        var formdata = new FormData();
        formdata.append('file',file);
        formdata.append('title',self.title);
        formdata.append('remark',self.remark);
        self.loading = true;
        self.showSave = false;
        request({
          url:'/CycleComputer/Import4Web',
          method:'post',
          data:formdata,
          headers:{
            'Content-Type':'multipart/form-data'
          }
          }).then(res=>{
            self.loading = false;
            self.showSave = true;
            this.$toasted.show('upload success!',{type:"info"});
            self.goBack();
        }).catch(ex=>{
           self.loading = false;
           this.$toasted.show(ex,{type:"error"});
        });
     },
     goBack()
     {
        this.$router.push('/Mine/Index');
     }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
input{outline: none;}
textarea{outline: none;}

.container{
  display: flex;
  width: 1200rem;
  margin:0 auto;
  flex-direction: column;
  .head{
    display: flex;
    height: 67rem;
    .back {
      width: 44rem;
      height: 44rem;
      padding-top:11rem;
      cursor: pointer;
    }
  }
  .body {
    display: flex;
    height: 653rem;
    width: 1200rem;
    background: #252525;
    flex-direction: column;
    margin-bottom:50rem;
    .body-top {
      display: flex;
      flex-direction:row;
      justify-content: space-between;
      .title {
        display: flex;
        font-size: 28rem;
        font-family: "GenSenMaruGothicTW-Bold";
        font-weight: 700;
        letter-spacing: 0;
        color: #fff;
        padding-top: 18rem;
        padding-left: 30rem;
      }
      .button-group{
        display:flex;
        padding-top: 20rem;
        padding-right: 30rem;

      }
      .button{
        width: 100rem;
        height: 38rem;
        background: #252525;
        border: 2rem solid #4D4D4D;
        border-radius: 100rem;
        color: #fff;
        cursor: pointer;
        margin-right: 20rem;
        font-family: "GenSenMaruGothicTW-Bold";
        font-size: 14rem;
        text-align: center;
      }
      .primary-button{
        width: 100rem;
        height: 38rem;
        background: #F93086;
        border: 0rem solid #4D4D4D;
        border-radius: 100rem;
        color: #fff;
        cursor: pointer;
        font-family: "GenSenMaruGothicTW-Bold";
        font-size: 14rem;
        text-align: center;
      }
      .disabled-button{
        width: 100rem;
        height: 38rem;
        background: #737373;
        border: 0rem solid #4D4D4D;
        border-radius: 100rem;
        color: #fff;
        font-family: "GenSenMaruGothicTW-Bold";
        font-size: 14rem;
        text-align: center;
      }
    }
    .upload-icon{
      margin-left:23.34rem;
      margin-right:10rem;
      margin-top:12.73rem;
      width: 16.74px;
      height: 15.39px;
    }
    
    .remark{
      width: 629rem;
      height: 20rem;
      font-family: "GenSenMaruGothicTW-Medium";
      font-size: 14rem;
      color: #737373;
      font-weight: 500;
      margin-left: 33rem;
    }
    .label{
      font-family: "GenSenMaruGothicTW-Bold";
      font-size: 16rem;
      color: #FFFFFF;
      font-weight: 700;
      margin-left: 30rem;
      margin-top:21rem;
    }
    .text{
      margin-left:30rem;
      margin-top:10rem;
      padding-left: 20rem;
      width: 561rem;
      height: 45rem;
      border: 2rem solid #4D4D4D;
      border-radius: 225rem;
      font-family: 'GenSenMaruGothicTW-Bold';
      font-size:14rem ;
      box-sizing: border-box;
     
    }
    .text:hover {
      border-color: #F93086;
    }
    .min-text{
      margin-top:10rem;
      padding-left: 20rem;
      box-sizing: border-box;
      width: 270rem;
      height: 45rem;
      border: 2rem solid #4D4D4D;
      border-radius: 225rem;
      font-family: 'GenSenMaruGothicTW-Bold';
      font-size:14rem ;
    }
    // .min-text:hover {
    //   border-color: #F93086;
    // }
    .rich-text{
      margin-left:30rem;
      margin-top:10rem;
      padding-left: 20rem;
      padding-top: 13rem;
      width: 561rem;
      height: 140rem;
      border: 2rem solid #4D4D4D;
      border-radius: 25rem;
      overflow-y: hidden;
      background: transparent;
      color:#fff;
      font-family: 'GenSenMaruGothicTW-Bold';
      font-size:14rem ;
      box-sizing: border-box;
      resize:none;
    }
    .rich-text:hover {
      border-color: #F93086;
    }
  }
}

input[type=file]{
    display: none;
    opacity: 0;
}

label{
  width: 270px;
  height: 45px;
  border: 2px solid #4D4D4D;
  border-radius: 225px;
  margin-left: 30rem;
  margin-top:10rem;
  color:#fff ;
  font-family: 'GenSenMaruGothicTW-Bold';
  font-size: 14rem;
  font-weight: 700;
  cursor: pointer;
  white-space:nowrap; 
   box-sizing: border-box;
}
label:hover {
  border-color: #F93086;
}

.line {
  width: 1200rem;
  height: 1px;
  background: #444444;
  opacity: 0.5;
  margin-top: 20rem;
}

</style>
