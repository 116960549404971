<template>
  <div class="no-scroll">
    <TopNavigator />
    <div class="routes">
      <div class="bg"></div>
      <div class="tltle">ROUTES</div>
      <div class="sub-title">coming soon</div>
      <router-link class="btn no-select" to="/">BACK TO HOME</router-link>
    </div>
    <BottomNavigator />
  </div>
</template>

<script>
import TopNavigator from "@/components/TopNavigator";
import BottomNavigator from "@/components/BottomNavigator";
export default {
  name: "Routes",
  data() {
    return {};
  },
  components: {
    TopNavigator,
    BottomNavigator,
  },
  mounted() {},
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang='scss'>
.routes {
  flex: 1;
  background: url("../../assets/404bg.png");
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
   min-height: 800rem;
    // min-height: calc(100vh - 85rem - 149rem);
  .tltle {
    // margin-top: 322rem;
    width: 100%;
    height: 222rem;
    font-family: "GenSenMaruGothicTW-Bold";
    font-size: 140rem;
    line-height: 140rem;
    color: #f93086;
    text-align: center;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .sub-title {
    margin-top: -60rem;
    width: 283rem;
    height: 78rem;
    text-align: center;
    font-family: "GenSenMaruGothicTW-Bold";
    font-size: 28rem;
    color: #ffffff;
    line-height: 78rem;
    font-weight: 700;
  }
  .btn {
    margin-top: 44rem;
    font-family: "GenSenMaruGothicTW-Bold";
    font-size: 20rem;
    color: #ffffff;
    text-align: center;
    font-weight: 700;
    width: 240rem;
    height: 60rem;
    background: #f93086;
    border-radius: 30rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
</style>
