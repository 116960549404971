<template>
  <div>
    <TopNavigator />
    <div>
        <div class="head">
            <div style="width:1200rem; margin:0 auto;">
                <div class="title">
                    HOW TO START WITH POWERFUN?
                </div>
                <!-- <a href="" class="btn">Learn more</a> -->
                <router-link to="/About/HowToStart" class="btn no-select" target="_blank">LEARN MORE</router-link>
            </div>
        </div>
        <div class="main">
            <div class="sub-title">Frequently Asked Questions</div>
            <div v-for="(item, index) in list" :key="item.title" class="item">
                <div class="title" @click="toggle(index)">
                    <div>{{ item.title }}</div>
                    <div class="btn plus" style="">
                        <img src="~@/assets/加号.png"/>
                    </div>
                    <div class="btn minus" style="background: #4d4d4d;">-</div>
                </div>
                <div class="content calculate" :id="'qa-'+index" :class="{ 'calculate': item.calculate }">
                    <div style="padding:40rem;">
                        {{ item.content }}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <BottomNavigator />
  </div>
</template>

<script>
import TopNavigator from "@/components/TopNavigator";
import BottomNavigator from "@/components/BottomNavigator";
export default {
    name: "About",
    data() {
        return {
            list:[{
                    title:'Do I have to pay for PowerFun?',
                    content:'PowerFun is completely free. Please feel free to use it.',
                },
                {
                    title:'Do I need an Internet connection to use PowerFun normally?',
                    content:'Yes, You need an Internet connection to use PowerFun normally.'
                },
                {
                    title:'How to register a PowerFun account?',
                    content:'In the login interface of PowerFun, select "Sign Up".'
                },
                {
                    title:'Why does the system prompt that the user does not exist when logging in？',
                    content:"In the login interface of PowerFun, select 'Sign Up'.If you have registered a PowerFun account, or you are prompted that the user name does not exist, please check whether the account is entered correctly, or try several frequently used email or mobile phone numbers."
                },
                {
                    title:'How do I pair devices?',
                    content:'After connecting through Ant + or Bluetooth and connecting to the computer correctly, open the PowerFun device connection page, and the system will automatically search for Ant + devices or Bluetooth connected devices. At this point, you need to pair the device displayed on the screen, including power meter, speedometer, heart rate meter, etc., until they are displayed as connected.'
                },
                {
                    title:'Why is the Bluetooth signal weak?',
                    content:'Bluetooth communication distance is limited, it is recommended to use this product within 3M.'
                },
                {
                    title:'How can I delete the records of the sections I have cycled.',
                    content:'You can click “Delete” in the personal riding list to delete the riding records.'
                },
                {
                    title:'Why do I get a password error when I log in?',
                    content:'Because the password you entered does not match the user name, it is recommended that we you check whether the password is correctly entered or retrieve and change the password through "Forget Password".'
                },
                {
                    title:'How do I change my password?',
                    content:'① Click "Forget Password" in the login interface, ② Enter your registration email, ③ Log in email to change your password.'
                }
            ]
        };
    },
    components: {
        TopNavigator,
        BottomNavigator,
    },
    mounted() {
        this.load();
    },
    methods: {
        load(){
        //   this.list.forEach(element => {
        //       element.calculate = true;
        //   });
           this.$nextTick(function(){
                for(let i=0; i< this.list.length; i++){                   
                    let height = document.getElementById('qa-'+i).clientHeight;
                    // console.log(i+':'+height);
                    this.list[i].height = height;

                    document.getElementById('qa-'+i).classList.remove('calculate');
                    document.getElementById('qa-'+i).classList.add('collapsing');
                }
            });
        },
        toggle(index){
            let ele = document.getElementById('qa-'+index);
            if(ele.style.height == ''){
                ele.style.height = this.list[index].height + 'px';
                ele.parentElement.classList.add('expand');
            }
            else{
                ele.style.height = '';
                ele.parentElement.classList.remove('expand');
            }
        }
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.head {
    height: 422rem;
    // text-align: left;
    background:url('~@/assets/Photo 1-min.png');
    background-size: cover;
   
    .title {
        font-size: 52rem;
        font-family: 'GenSenMaruGothicTW-Bold';
        color:#fff;
        font-weight: 700;
        padding-top: 112rem;
    }
    .btn {
        color:#fff;
        text-align: center;
        font-weight: 700;
        font-size: 20rem;
        font-family: 'GenSenMaruGothicTW-Bold';
        border-radius: 31rem;
        background: #f93086;
        width:196rem;
        height: 60rem;
        display: block;
        text-decoration: none;
        line-height: 60rem;
        margin-top:50rem;
    }
}
.main {
    width:1200rem;
    margin:0rem auto 50rem auto;
    .sub-title {
        color:#fff;
        font-size: 42rem;
        font-weight: 700;
        font-family: 'GenSenMaruGothicTW-Medium';
        padding:30rem;
        height: 78rem;
        line-height: 78rem;
        padding: 26rem 0 11rem 0;
    }

    .item {
        padding-bottom: 20rem;
        position: relative;
        .title {
            height: 78rem;
            background: #292929;
            border-radius: 8rem;
            color:#fff;
            font-size: 22rem;
            font-family: 'GenSenMaruGothicTW-Medium';
            font-weight: 700;
            line-height: 78rem;
            padding-left: 40rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;

            .btn {
                width: 46rem;
                height: 46rem;
                border-radius: 100%;
                display: inline-block;
                text-align: center;
                line-height: 46rem;
                margin-right: 30rem;
                cursor: pointer;
                user-select:none;
                &.plus {
                    //background:url(~@/assets/2-facebook.png);
                    font-weight: normal;
                    font-size: 35rem;
                    background: #f93086; 
                    transition:background-color 0.66s, transform 0.66s, color 0.66s;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding:12rem;
                    box-sizing: border-box;
                }
                &.minus {
                    display: none;
                }
            }
        }
        .content {
            font-size: 18rem;
            font-family: "GenSenMaruGothicTW-Regular";
            color:#a6a6a6;
            line-height: 30rem;
            font-weight: 500;
            // padding:30rem;
            background: #252525;
            // height: 0;
            overflow: hidden;
            display: none;
            word-break:break-word;

            &.calculate {
                display: block;
                visibility: hidden;
                position: absolute;
            }

            &.collapsing {
                height: 0;
                display: block;
                transition: height .3s ease;
            }
        }
        &.expand {
            .title {
                .plus {
                    // display: none;
                    background: #4d4d4d;
                    transform:rotate(45deg);
                    // color:#000;
                }
                // .minus {
                //     display: inline-block;
                // }
            }
        }
    }    
}


</style>
