<template>
    <div>
        <TopNavigator />
        <div class="wrap">
            <div>
                <div class="title">Privacy Policy</div>
                <div class="content">
                    <p><span class="title">Commitment to Privacy</span></p>
                     <div class="sub-content">
                        <p><span >Welcome to PowerFun.com and Related Apps PowerFun Desktop, Mobile and Tablet Apps, PowerFun, PowerFun Workouts, (collectively referred to as &quot;PowerFun&quot;, &quot;We&quot;, &quot;We&quot; or &quot;Our&quot;).</span></p>
                        <p><span >&nbsp;</span></p>
                        <p><span >We respect your right to privacy. Your ability to make informed choices about the use of your information is important to us. We want to make sure that you understand the type of information we collect about you, how it is used and the safeguards we use to protect it. This Privacy Policy explains our policy regarding the collection, use, disclosure and protection of personal information you share with us.</span></p>
                        <p><span >&nbsp;</span></p>
                        <p><span >Before you get to the details, here are a few of our fundamental privacy principles:</span></p>
                        <p><span >Information you upload or post to Powerfun may be seen by others depending on your privacy settings.</span></p>
                        <p><span >We aggregate and de-identify certain information about our members to use for business purposes.</span></p>
                        <p><span >We give you several ways to control the privacy of your personal information and are continuously working to enhance privacy options available to you.</span></p>
                        <p><span >Please also read the Terms of Use which sets out the terms governing Powerfun and the Powerfun Legal Cookie Statement.</span></p>
                        <p><span >&nbsp;</span></p>
                        <p><span >Address: Highway 109, Jingkai District, Wuxi City, Jiangsu Province, China</span></p>
                        <p><span >&nbsp;</span></p>
                        <p><span >What Information does Powerfun Collect?</span></p>
                        <p><span >The type of information that we collect and retain depends upon your interaction with Powerfun. Here is a short overview of the two main types of interactions.</span></p>
                        <p><span >&nbsp;</span></p>
                        <p><span >Information You Give Us. We collect the personally identifiable information you provide to us:</span></p>
                        <p><span >&nbsp;</span></p>
                        <p><span >Create a user account, place an order, register a product (information we collect is necessary to complete the account registration and transaction and so that we can ensure you Powerfun products and services):</span></p>
                        <ul>
                            <li>Username (unique identification)</li>
                            <li>Password (security purpose)</li>
                            <li>E-Mail address (basic communication)</li>
                            <li>User Weight (to be able to control the indoor trainer)</li>
                            <li>User Height (to be able to control the indoor trainer)</li>
                            <li>Date Of Birth (to be able to calculate a default power / heart rate zones, FTP)</li>
                            <li>Language</li>
                            <li>Country (Tax calculation, Limitation for payment gateways based on the country)</li>
                        </ul>
                        <p><span >Requesting product support or other services (information we collect is necessary to, provide support and to respond to queries such as your name and address and information about your computer hardware, software (including your License Number), and the nature of the problem you are experiencing).</span></p>
                        <p><span >Participate in surveys (information we collect may include demographic information such as age, gender and number of household members).</span></p>
                        <p><span >You have the final decision on whether to proceed with any activity that requests personal information. However, if you do not wish to provide information needed for a transaction, you may not be able to complete it.</span></p>
                        <p><span >&nbsp;</span></p>
                        <p><span >Information Powerfun Collects. When you interact with Powerfun we may maintain a record of the following types of information:</span></p>
                        <p><span >&nbsp;</span></p>
                        <p><span >We collect basic account information such as your name, email address, date of birth, gender, prefered language, country, username and password that helps secure and provide you with access to our Services.</span></p>
                        <p><span >Profile and use information is collected about you when you choose to upload a picture, activity (including date, time and geo-location information as well as your speed and pace) or post, join a challenge, add your equipment usage, view others&rsquo; activities, or otherwise use Powerfun.</span></p>
                        <p><span >Powerfun collects information from devices where you installed and ran Powerfun App.</span></p>
                        <p><span >We gather information from the photos, posts, comments, and other content you share on Powerfun, including when you participate in partner events or routes.</span></p>
                        <p><span >When you make a payment on Powerfun, you may provide payment information such as your payment card or other payment details. We use Payment Card Industry compliant third-party payment services and we do not store your credit card information.</span></p>
                        <p><span >We collect and process location information when you sign up for and use Powerfun. To record your ride and to provide you with your training statistics and inclusion in the Powerfun&rsquo;s features (for example, the leaderboard for a route), it is necessary to collect and record the physical location of your device including, data such as speed and direction. Processing of your location data is essential to Powerfun which we provide and a necessary part of our performance of the agreement we have with you.</span></p>
                        <p><span >Powerfun allows you to sign up and log in to Powerfun using accounts you create with third-party products and services, such as Facebook or TrainingPeaks (collectively, &ldquo;Third-Party Accounts&rdquo;). If you access Powerfun with Third-Party Accounts we will collect information that you have agreed to make available such as your name, e-mail address, profile information and preferences with the applicable Third-Party Account. This information is collected by the Third-Party Account provider and is provided to Powerfun under their privacy policies. You can generally control the information that we receive from these sources using the privacy settings in your Third-Party Account.</span></p>
                        <p><span >To help us to provide you with the best possible service, you can choose to provide us with your contact information so we can better respond to your support requests and comments.</span></p>
                        <p><span >We also obtain additional third party information about members from marketers, partners, researchers, and others. We may combine information that we collect from you with information obtained from other members, third parties and information derived from any other subscription, product, or service we provide. We may also collect information about you from other members such as when they give you kudos or comment about you.</span></p>
                        <p><span >We collect information from your browser, computer, or mobile device, which provide us with technical information when you access or use Powerfun. This technical information includes device and network information, cookies, log files and analytics information.</span></p>
                     </div>
                    <p><span class="title" >Do We Use Cookies?</span></p>
                    <div class="sub-content">
                        <p><span >Cookies are small pieces of unique information, sent by a website, that are stored on your computer&rsquo;s hard drive by your web browser. Cookies enable a website to tailor information presented to you based on your browsing preferences. Powerfun uses cookies to personalize your Powerfun, when you seek service &amp; support or to remember you when you register for products or services.</span></p>
                        <p><span >&nbsp;</span></p>
                        <p><span >Powerfun may also use cookies to track your visit to Powerfun site. While our web servers may automatically log your IP address, the type of information we collect pertains to how you navigate, browse and purchase. This information is not personally identifiable, and we do not correlate this information with your personal information.</span></p>
                        <p><span >&nbsp;</span></p>
                        <p><span >However, rejecting cookies may affect your ability to use some of the products and/or services of Powerfun.</span></p>
                    </div>
                    <p><span >&nbsp;</span></p>
                    <p><span class="title">How Do We Use The Information We Collect?</span></p>
                    <div class="sub-content">
                        <p><span >The personal information we collect is generally used to process your requests and transactions, to provide you with high quality service, to tell you about opportunities we think will be of interest to you, to customize your Powerfun experience and to understand your needs so that we may provide you with the most suitable products and services. For example, we may use your personal information to help us:</span></p>
                        <p><span >&nbsp;</span></p>
                        <p><span >Provide you with information about product upgrades, new products, services, research on future product ideas or improvements;</span></p>
                        <p><span >Send newsletters, informative e-mails or other information which has the nature of business announcements. Powerfun reserves the right to use customers&rsquo; contact information to send them information it deems important for the full utilization of its products and services. Every registered customer may choose not to receive such information from Powerfun by simply replying to any message to that effect and writing the word &ldquo;unsubscribe&rdquo; in the subject line (with the exception of messages which are necessary for the functionality of our products and services);</span></p>
                        <p><span >Create content that is relevant to you;</span></p>
                        <p><span >Provide you with special offers that may be of interest to you;</span></p>
                        <p><span >Assist us in creating better, customized products and services to meet your needs;</span></p>
                        <p><span >Allow you to purchase and download products, obtain access to services or otherwise engage in activities you select;</span></p>
                        <p><span >Help you quickly find software, services, or product information important to you;</span></p>
                        <p><span >Allow you to participate in interactive features of our service, when you chose to do so; and</span></p>
                        <p><span >Notify you about changes to our service.</span></p>
                    </div>
                    <p><span class="title">With Whom Do We Share Your Information?</span></p>
                    <div class="sub-content">
                        <p><span class="sub-title">1.Third Parties</span></p>
                        <p><span >Powerfun does not sell or rent your personally identifiable information to third parties. Under certain circumstances and from time to time, Powerfun may share your personally identifiable information with third parties as set forth below. In such circumstances, we provide only the information necessary to perform the function or to provide the service for which it has been shared. These third parties are required to keep the information confidential and are not permitted to use the personal information provided by us for any purpose, except as authorized by Powerfun.</span></p>
                        <p><span >&nbsp;</span></p>
                        <p><span >Powerfun may share your information with third parties such as:</span></p>
                        <p><span >&nbsp;</span></p>
                        <p><span >Service Providers: We may share your personally identifiable information with trusted service providers who provide operational or other support services to our Customers or who perform certain functions on our behalf. Examples include providers who fulfill orders, ship products, process transactions (including credit card transactions), distribute e-mails, conduct research or statistical analysis or provide other marketing assistance. It may be necessary to share user personal information with these service providers in order to allow them to provide their services. You have the right not to provide the requested information, but in such case you may not be able to receive these services. Those service providers will be permitted to obtain only the personal information they need to deliver the service and we require that they keep the personal information of our customers secure and confidential and comply with the Act and all other applicable laws. We also require that these contractors use our customers&rsquo; personal information only on behalf of Powerfun and do not disclose them to third parties or use them for any other purpose.</span></p>
                        <p><span >Other Disclosures: We may share your personally identifiable information if we believe in good faith that such disclosure is: a) authorized by law or necessary to comply with a legal process; b) in response to legal claims; c) required to protect and defend the rights, property or legitimate interests of Powerfun (including the enforcement of our agreements); d) necessary to protect personal safety, property or other rights of Powerfun and its customers or employees; or e) an integral part of a sale of all or part of our business.</span></p>
                    </div>
                    <p><span class="title">Consent</span></p>
                    <div class="sub-content">
                        <p><span >By providing us with your personal information when registering to Powerfun or when you obtain or use any of our products or services, you consent to the collection, use and disclosure by Powerfun of your personal information as provided in this Privacy Policy.</span></p>
                        <p><span >&nbsp;</span></p>
                        <p><span >Your consent includes the retention of personal information that is collected through Powerfun, including your personal information that you submit through a registration process, for as long as you are a user of Powerfun&rsquo; products and services, and for a reasonable time thereafter, unless and until you withdraw your consent. Information associated with your account will generally be kept until it is no longer necessary to provide Powerfun or until you ask us to delete it or your account is deleted whichever comes first.</span></p>
                        <p><span >&nbsp;</span></p>
                        <p><span >Information about you that is no longer necessary and relevant to provide our Services may be de-identified and aggregated with other non-personal data to provide insights which are commercially valuable to Powerfun, such as statistics of the use of Powerfun. This information will be de-associated with your name and other identifiers.</span></p>
                        <p><span >&nbsp;</span></p>
                    </div>
                    <p><span class="title">Security</span></p>
                    <div class="sub-content">
                        <p><span >Powerfun is committed to maintaining the security of information under our control. We have taken security measures, consistent with industry practices, to protect your personal information. To try to prevent unauthorized access, maintain data accuracy and ensure correct use of information, we have put in place industry-accepted physical, technical and administrative practices to safeguard and secure the information we collect.</span></p>
                        <p><span >&nbsp;</span></p>
                        <p><span >Where we have given you (or where you have chosen) a password which enables you to access certain parts of Powerfun, you are responsible for keeping this password confidential. To protect the confidentiality of this category of personal information, you must keep your password confidential and not disclose it to any other person.</span></p>
                        <p><span >&nbsp;</span></p>
                        <p><span >Unfortunately, no transmission of information over the internet can be guaranteed to be completely secure. Although we will do our best to protect your personal information, we cannot guarantee the security of any information you transmit to Powerfun from your PC or the Internet Service Provider, and any transmission is at your own risk. Once we have received your information, we will use strict procedures and security features to ensure its security on our system and to try to prevent unauthorized access.</span></p>
                        <p><span >&nbsp;</span></p>
                        <p><span >All information you provide to us is stored on our secure servers. All information is transmitted using secure socket layer (SSL) encryption. Powerfun engages a company that is an industry leader in online security and Services verification to strengthen the security of Powerfun&rsquo;s Services. Powerfun are registered with site identification authorities so that your browser can confirm Powerfun&rsquo;s identity before any personally identifiable information is sent. In addition, Powerfun&rsquo;s secure servers protect this information using advanced firewall technology. We restrict access to personal information about you to those Powerfun employees who need to know the information for the purposes described in this Privacy Policy.</span></p>
                        <p><span >&nbsp;</span></p>
                        <p><span >To help ensure that these measures are effective in preventing unauthorized access to your private information, you should be aware of the security features available to you through your browser. You should use a security-enabled browser to submit your credit card information and other personal information at Powerfun. Please note that if you do not use a SSL-capable browser, you are at risk for having data intercepted.</span></p>
                        <p><span >&nbsp;</span></p>
                    </div>
                    <p><span class="title">Managing Your Settings</span></p>
                    <div class="sub-content">
                        <p><span >Powerfun offers several features and settings to help you manage your privacy and how you share your activities. The most important is the level of privacy in your privacy settings page. There you can set up the level of publicity of your data. Subject to your privacy settings, your information and content may be publicly accessible.</span></p>
                        <p><span >&nbsp;</span></p>
                        <p><span class="sub-title">1.Adjust Notification and Email Preferences</span></p>
                        <p><span >Powerfun offers various ways to manage the notifications you receive. You can choose to stop receiving certain emails and notifications by indicating your preference at Powerfun Portal Settings. You may also unsubscribe by following the instructions contained at the bottom of each type of email. Any administrative or service-related emails (to confirm a purchase, or an update to this Privacy Policy or the Terms of Use, etc.) generally do not offer an option to unsubscribe as they are necessary to provide Powerfun you requested.</span></p>
                        <p><span >&nbsp;</span></p>
                        <p><span class="sub-title">2.Updating Account Information</span></p>
                        <p><span >You may correct, amend or update profile or account information that is inaccurate at any time by adjusting that information in your account settings.</span></p>
                        <p><span >&nbsp;</span></p>
                        <p><span class="sub-title">3.Deleting Information and Accounts</span></p>
                        <p><span >You may request that your account is deleted by visiting the user profile settings on Powerfun.</span></p>
                        <p><span >&nbsp;</span></p>
                        <p><span class="sub-title">4.GDPR Rights</span></p>
                        <p> <span class="third-sub-title">Access and Porting</span></p>
                        <p><span >You can access much of your information by logging into your account. To download a copy of your data contact us via e-mail service@thinkrider.com.</span></p>
                        <p><span >&nbsp;</span></p>
                        <p><span class="third-sub-title">Rectify, Restrict, Limit, Delete</span></p>
                        <p><span >You can delete much of your information by logging into your account. If you are unable to do this, please contact us at https://www.powerfun.com. Powerfun will generally respond to your request within 15-20 business days.</span></p>
                        <p><span >&nbsp;</span></p>
                        <p><span class="third-sub-title">Complain</span></p>
                        <p><span >If you have provided personal information to Powerfun you may ask us to advise you of the scope of information Powerfun processes about you. You should wish to raise a concern about our use of your information. Please contact as via e-mail service@thinkrider.com.</span></p>
                        <p><span >&nbsp;</span></p>
                        <p><span >Furthermore, we believe that the individual is invaluable in ensuring that data is complete and accurate. We will provide you with access to personally identifiable information you provide through Powerfun for as long as we maintain that information in a readily accessible format. Similarly, we permit and encourage you to correct inaccuracies in the information you submit to us through Powerfun. If you believe that Powerfun does not have your current personal information, you can update it by visiting the user profile settings on Powerfun.</span></p>
                        <p><span >&nbsp;</span></p>
                    </div>
                    <p><span class="title">Links To Other Companies</span></p>
                    <div class="sub-content">
                        <p><span >Periodically, Powerfun may contain links to and from websites of our business partners, distributors, resellers, advertisers or other third parties. If you follow a link to any of these websites, please note that these websites have their own privacy policies which you should read before you submit any personal data to these websites. We disclaim any liability for the data processing activities of our business partners, distributors and resellers, all of which act as independent data processing controllers.</span></p>
                        <p><span >&nbsp;</span></p>
                    </div>
                    <p><span class="title">Co-branded Websites</span></p>
                    <div class="sub-content">
                        <p><span >We also link to co-branded websites that are maintained by Powerfun and one or more of our business partners. An example of a co-branded website is the Powerfun e-shop. When you submit information to one of our co-branded websites, you may be submitting it to both Powerfun and our business partners. Please note that the privacy policies of these co-branded websites may differ from this Privacy Policy. We encourage you to read the privacy policies on any co-branded website to which you link, and we disclaim any liability for the data processing activities of our business partners which act as independent data processing controllers.</span></p>
                        <p><span >&nbsp;</span></p>
                    </div>
                    <p><span class="title">Discussion Groups and Forums</span></p>
                    <div class="sub-content">
                        <p><span >Please remember that information you submit in a discussion group or forum at Powerfun will be deemed public information and is not confidential. There is a risk in such communication venues that various information and data can be collected and used by others. Please be careful and responsible when you are on-line.</span></p>
                        <p><span >&nbsp;</span></p>
                    </div>
                    <p><span class="title">Changes of Privacy Policy</span></p>
                    <div class="sub-content">
                        <p><span >Powerfun reserves the right to modify this Privacy Policy at any time. Please review it occasionally. If Powerfun makes changes to this Privacy Policy, the updated Privacy Policy will be posted on the Services in a timely manner and, if we make material changes, we will provide a prominent notice. If you object to any of the changes to this Privacy Policy, you should stop using the Services and delete your Powerfun account.</span></p>
                        <p><span >&nbsp;</span></p>
                    </div>
                    <p><span class="title">Residents of the European Economic Area</span></p>
                    <div class="sub-content">
                        <p><span >If you are a resident of the EEA, you have certain rights and protections under the law regarding the processing of your personal data.</span></p>
                        <p><span >&nbsp;</span></p>
                        <p><span class="sub-title">1.Legal Basis For Processing</span></p>
                        <p><span >If you are a resident of the EEA, when we process your personal data we will only do so in the following situations:</span></p>
                        <p><span >&nbsp;</span></p>
                        <p><span >We have your consent to do so (e.g., sharing your data with others in the&nbsp;</span><span >Powerfun</span><span >&nbsp;community to help make our workouts interactive). You can withdraw your consent at any time by managing your setting in the Companion App or contacting us at&nbsp;</span><span style="font-family:Calibri;font-size:14px;">service@thinkrider.com</span><span >.</span></p>
                        <p><span >&nbsp;</span></p>
                        <p><span >We need to use your personal data to perform our responsibilities under our contract with you (e.g., processing payments for and providing the&nbsp;</span><span >Powerfun</span><span >&nbsp;Services you have requested).</span></p>
                        <p><span >&nbsp;</span></p>
                        <p><span >We have a legitimate interest in processing your personal data. For example, we may process your personal data to communicate with you about changes to our Services, and to provide, secure, and improve our Services.</span></p>
                        <p><span >&nbsp;</span></p>
                        <p><span  class="sub-title">2.Your EEA Rights</span></p>
                        <p><span >If you are a resident of the EEA, you have the right to access personal data we hold about you and to ask that your personal data be corrected, deleted, or transferred. You may also have the right to object to, or request that we restrict, certain processing.</span></p>
                        <p><span >&nbsp;</span></p>
                        <p><span >Where you have provided your personal data to us with your consent, you have the right to ask us for a copy of this data in a structured, machine readable format and to ask us to share (port) this data to another data controller.</span></p>
                        <p><span >&nbsp;</span></p>
                        <p><span >In certain circumstances, you have the right to ask us to delete the personal data we hold about you:</span></p>
                        <p><span >&nbsp;</span></p>
                        <ul>
                            <li >where you believe that it is no longer necessary for us to hold your personal data (for example, if you decide that you no longer wish have an account with us);</li>
                            <li >where we are processing your personal data on the basis of legitimate interests and you object to such processing and we cannot demonstrate an overriding legitimate ground for the processing;</li>
                            <li >where you have provided your personal data to us with your consent and you wish to withdraw your consent and there is no other ground under which we can process your personal data; or</li>
                            <li >where you believe the personal data we hold about you is being unlawfully processed by us.</li>
                        </ul>
                        <p><span >&nbsp;</span></p>
                        <p><span >In certain circumstances, you have the right to ask us to restrict (stop any active) processing of your personal data:</span></p>
                        
                        <p><span >&nbsp;</span></p>

                        <ul>
                            <li >where you believe the personal data we hold about you is inaccurate and while we verify accuracy;</li>
                            <li >where we want to erase your personal data as the processing is unlawful but you want us to continue to store it;</li>
                            <li >where we no longer need your personal data for the purposes of our processing but you require us to retain the data for the establishment, exercise or defence of legal claims; or</li>
                            <li >where you have objected to us processing your personal data based on our legitimate interests and we are considering your objection.</li>
                        </ul>

                        <p><span >In addition, you can object to our processing of your personal data based on our legitimate interests and we will no longer process your personal data unless we can demonstrate an overriding legitimate ground.</span></p>
                        <p><span >&nbsp;</span></p>
                        <p><span >If you would like to exercise any of these rights, you can log into your account and edit your profile. Alternatively, you may contact us as indicated below. We will respond to your request within a reasonable timeframe.</span></p>
                        <p><span >&nbsp;</span></p>
                        <p><span >Please note that these rights are limited, for example, where fulfilling your request would adversely affect other individuals or&nbsp;</span><span >Powerfun</span><span >&nbsp;trade secrets or intellectual property, where there are overriding public interest reasons or where we are required by law to retain your personal data.</span></p>
                        <p><span >&nbsp;</span></p>
                        <p><span  class="sub-title">3.Questions or Complaints</span></p>
                        <p><span >If you are a resident of the EEA and have a concern about our processing of personal data, please contact us in the first instance at&nbsp;</span><span style="font-family:Calibri;font-size:14px;">service@thinkrider.com</span><span >&nbsp;and we will endeavor to deal with your request. This is without prejudice to your right to launch a claim with the data protection supervisory authority in the EU country in which you live or work where you think we have infringed data protection laws that we are not able to resolve, you have the right to lodge a complaint with the data privacy authority where you reside.</span></p>
                        <p><span >&nbsp;&nbsp;</span></p>
                        <p><span >&nbsp;</span></p>
                    </div>
                    <p><span class="title">Residents of California</span></p>
                    <div class="sub-content">
                        <p><span >If you are a resident of California, you have certain rights and protections under the law regarding the processing of your information.</span></p>
                        <p><span >&nbsp;</span></p>
                        <p><span class="sub-title">1.Powerfun&rsquo;s Practices With Respect To Personal Information</span></p>
                        <p><span >As described in the preceding sections of this Privacy Policy, in the last 12 months, we have collected the following categories of information about you:</span></p>
                        <p><span >&nbsp;</span></p>
                        <p><span >Identifiers, such as your first and last name, email address, and home address;</span></p>
                        <p><span >&nbsp;</span></p>
                        <p><span >Demographic information or characteristics that are protected under applicable law, such as your gender;</span></p>
                        <p><span >&nbsp;</span></p>
                        <p><span >Commercial information, including a record of your payments and transactions;</span></p>
                        <p><span >&nbsp;</span></p>
                        <p><span >Internet or similar network activity, including how you interact with the Service, the routes or programs you choose, details about your workout, your interactions with other users, and information about your device;</span></p>
                        <p><span >&nbsp;</span></p>
                        <p><span >Geolocation data, such as the country or area in which you are located; and</span></p>
                        <p><span >&nbsp;</span></p>
                        <p><span >Inferences drawn from other information we collect, which help us better understand you and personalize the Service to you.</span></p>
                        <p><span >&nbsp;</span></p>
                        <p><span >We receive this information from you, either when you provide it to us directly or when we collect it automatically from you, as well as from other&nbsp;</span><span >Powerfun</span><span >&nbsp;users, from your device, and from any social media or other platforms you link with your&nbsp;</span><span >Powerfun</span><span >&nbsp;account.</span></p>
                        <p><span >&nbsp;</span></p>
                        <p><span >We may share any or all of the above categories of information when you direct us to do so, such as when you interact with other users or choose to link your&nbsp;</span><span >Powerfun</span><span >&nbsp;account with a third-party service. We may also share these categories of information with our service providers for business purposes, or in other limited circumstances described in the &ldquo;Sharing of Information&rdquo; section above.</span></p>
                        <p><span >&nbsp;</span></p>
                        <p><span class="sub-title">2.Your California Rights</span></p>
                        <p><span >If you reside in California, you have the right to:</span></p>
                        <p><span >&nbsp;</span></p>
                        <p><span >Request to know and access the specific pieces and categories of personal information we have collected about you in the past year, as well as the categories of sources from which we collected that information, the business or commercial purposes for collecting that information, the categories of third parties with whom we have shared your information, and the categories of information that we have shared with those third parties.</span></p>
                        <p><span >&nbsp;</span></p>
                        <p><span >Request deletion of your personal information (subject to certain exceptions).</span></p>
                        <p><span >&nbsp;</span></p>
                        <p><span >Request certain information about whether or how we may have shared your information with third parties for their own direct marketing purposes during the preceding calendar year.</span></p>
                        <p><span >&nbsp;</span></p>
                        <p><span >Not to be discriminated against for exercising any of the rights listed above.</span></p>
                        <p><span >&nbsp;</span></p>
                        <p><span >To submit a request or otherwise exercise these rights, you can call +86 15077882683</span><span >&nbsp;</span><span >or email us at&nbsp;</span><span style="font-family:Calibri;font-size:14px;">service@thinkrider.com</span><span >. We may need to collect additional information from you in order to verify your identity before responding to your request. You may also designate an authorized agent to make a request on your behalf, in which case we will ask for proof of your authorization before responding to the request.</span></p>
                        <p><span >&nbsp;</span></p>
                    </div>
                    <p><span class="title">Language</span></p>
                    <div class="sub-content">
                        <p><span >This privacy policy was originally written in Chinese. Although Powerfun may provide one or more translated versions, if there are any conflicts or discrepancies, the Chinese version shall prevail.</span></p>
                        <p><span >&nbsp;</span></p>
                    </div>
                    <div class="sub-content">
                        <p><span class="title">Contact Us</span></p>
                        <p><span >If you have any questions or suggestions regarding our Privacy Policy, please contacts us at service@thinkrider.com</span></p>
                    </div>
                </div>
            </div>
        </div>
        <BottomNavigator />
    </div>
</template>

<script>
import TopNavigator from "@/components/TopNavigator";
import BottomNavigator from "@/components/BottomNavigator";
export default {
    name:'Privacy',
    components: {
        TopNavigator,
        BottomNavigator,
    },
    mounted(){

    },
    methods: {

    }
}
</script>

<style scoped lang="scss">
.wrap {
    margin-bottom: 20rem;
    >div {
        width:1200rem;
        margin:0 auto;
        background: #252525;
        padding-bottom:21rem ;
        .title {
            color:#fff;
            height: 58rem;
            line-height: 58rem;
            font-size: 42rem;
            text-align: center;
            font-weight: 700;
            padding-top:21rem;
            font-family: "GenSenMaruGothicTW-Regular";
        }
        .content {
            margin-top: 31rem;
            border-top: 1rem solid #444444;
           
            font-family: "GenSenMaruGothicTW-Regular";
            p {
                font-size: 15rem;
                color:#a6a6a6;
                line-height: 1.8;
                padding-left: 25rem;
                padding-right: 25rem;
            }
            ul{
                font-size: 15rem;
                color:#a6a6a6;
                font-weight: 400;
                padding-right: 25rem;
            }
            .title{
                font-size: 32rem;
                color: #fff;
                font-weight: 700;
            }
            .sub-title{
                font-size: 20rem;
                color: #fff;
            }
            .sub-content{
                margin-bottom:38rem;
                line-height:21rem;
            }
            .third-sub-title{
                font-size: 15rem;
                color: #fff;
                font-weight: 400;
            }
        }
    }
}
</style>